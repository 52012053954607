<template>
  <div class="timeline">
    <div
      class="timeline-item"
      v-for="item in items"
      :key="item.id"
      @click="handleTimeLineClick(item)"
    >
      <p class="date" @click="handleTimeLineClick(item)">
        {{ formatDate(item.date as string) }}
      </p>
      <div
        class="timeline-dot"
        :class="getDotStatus(item)"
        @click="handleTimeLineClick(item)"
      ></div>
      <p class="description" @click="handleTimeLineClick(item)">
        {{ item.message }}
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { formatDate } from "@/common/date";
import { useRouter } from "vue-router";
defineProps({
  items: {
    type: Array as () => Array<TimelineItem>,
    required: true,
  },
});
interface TimelineItem {
  id: number;
  date: string;
  message: string;
  isCompliant: boolean;
  isCompliantWithoutTolerance: boolean;
}
const emit = defineEmits(["timelineClick"]);
const router = useRouter();
const handleTimeLineClick = (slotProps: { id: number }) => {
  if (slotProps.id) {
    router.push({
      name: "enquadramentos-detalhes",
      params: { id: slotProps.id },
      force: true,
    });
    setTimeout(() => {
      emit("timelineClick");
    }, 100);
  }
};
const getDotStatus = (item: {
  isCompliant: boolean;
  isCompliantWithoutTolerance: boolean;
}) => {
  console.log(item);
  const { isCompliant, isCompliantWithoutTolerance } = item || {};
  switch (true) {
    case isCompliant === true && isCompliantWithoutTolerance === true:
      return "success-marker";
    case isCompliant === true && isCompliantWithoutTolerance === false:
      return "warning-marker";
    case isCompliant === false && isCompliantWithoutTolerance === false:
      return "error-marker";
    default:
      return "none";
  }
};
</script>
<style lang="scss" scoped>
.timeline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 2rem 0;
  .timeline-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 1;
    min-width: 120px;
    flex-shrink: 0;
    flex-grow: 1;
    &::before {
      content: "";
      position: absolute;
      top: 70%;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #ccc;
      z-index: -1;
    }
    &:first-child {
      &:before {
        left: 50%;
      }
    }
    &:last-child {
      &:before {
        right: 50%;
      }
    }
    .date {
      font-size: 0.875rem;
      font-weight: bold;
      color: #333;
      word-wrap: break-word;
      cursor: pointer;
    }
    .timeline-dot {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #ccc;
      border: 2px solid #fff;
      margin: 0.5rem 0;
      cursor: pointer;
    }
    .description {
      cursor: pointer;
      font-size: 14px;
      line-height: 120%;
      color: #666;
      margin-top: 0.5rem;
      text-wrap: wrap;
      width: 150px;
      position: absolute;
      bottom: -100%;
      color: #766d68;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
    }
  }
}
.success-marker {
  background-color: green !important;
}
</style>
