import { AxiosError } from "axios";
import RequestService from "@/services/ApiService";
import buildQueryString from "@/common/queryString";
import {
  FrameworkGroupedResponse,
  FrameworkResponse,
} from "@/core/interfaces/Framework";
import { FrameworkDetailsInterface } from "@/core/interfaces/FrameworkDetails";
import { XlsxDownloadInterface } from "@/core/interfaces/DownloadXlsx";
const requestService = new RequestService();

export class PdfService {
  public static async downloadFundFrameworks(id: number): Promise<Buffer> {
    let path = "/pdf/fund-framework/generate/";

    const response = await requestService.get(path + id, {
      responseType: "arraybuffer",
    });

    return response as Buffer;
  }
}
