<template>
    <div class="alert-wrapper mt-3">
    
            <div class="alert-wrapper__content" :class="variant()">
                <span class="span flex gap-4 text-[16px] items-center">
                    <img :src="getImg" alt="icon" class="w-7" />
                {{ message }}                                  
                </span>  
                <img class="cursor-pointer" src="../assets/svgs/redirect.svg"  @click="$emit('redirectTo')"  />         
            </div> 
           
      
    </div>
</template>

<script lang="ts" setup>

import { computed } from 'vue';

const props = defineProps({
	variant: {
		type: String,
		required: true
	},
	message: {
		type: String,
		required: true
        
	}
});


const variant = () => {
	switch (props.variant) {
	case 'warning':
		return 'warning';    
	default:
		break;
	}
};


const getImg = computed(() => {
	let icon = '';

	switch (props.variant) {
	case 'warning':
		icon = 'warning-icon';            
		break;
    
	default:
		break;
	}

	const url = new URL(`../assets/svgs/${icon}.svg`, import.meta.url).href;

	return url;
});


</script>


<style lang="scss" scoped>
.alert-wrapper {
     
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;       
        @media (max-width: 1600px) {
            flex-direction: column;
            gap: 1rem;
        }
    }
}


.warning {
    background: theme('colors.warning.DEFAULT');
    padding: 0.8rem 1rem;
    margin: 0.3rem 0;
    border-radius: 8px;
    width: 100%;
    color: theme('colors.warning.1');
    font-weight: 500;
}

.warning-button {
    border: solid 1px theme('colors.warning.1');
    padding: 0.2rem 1rem;
    font-weight: 500;
    &:hover {
        background: theme('colors.warning.1');
        color: theme('colors.white.DEFAULT');
    }
}


</style>

<style lang="scss">
.alert-button span {
    @media (max-width: 1400px) {
       font-size: 16px;
    }
}

</style>