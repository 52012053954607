<template>
  <form @submit.prevent="handleFormSubmit">
    <div class="custom-grid create-fund-wrapper">
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Código</label>
        <InputText
          disabled
          required
          v-model="form.code"
          class="global-input"
          :loading="loading"
        />
      </div>
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">CNPJ Emissor</label>
        <InputText
          disabled
          :loading="loading"
          v-maska
          data-maska="##.###.###/####-##"
          required
          v-model="form.issuerDocument"
          class="global-input"
        />
      </div>

      <!-- <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Tipo</label>
        <InputText
          disabled
          :loading="loading"
          required
          v-model="type"
          class="global-input"
        />
      </div> -->
    </div>
    <hr class="mt-5" />
    <div class="flex justify-end gap-5">
      <Button
        label="Voltar"
        class="global-button secondary-button rounded-lg"
        @click="router.push({ name: 'ativos' })"
      />
      <Button
        :loading="loading"
        :disabled="
          loading || !selectedFundType || !type || !issuerDocument || !code || !formWatcher
        "
        type="submit"
        :label="'Salvar'"
        class="global-button primary-button rounded-lg"
      />
    </div>
  </form>
</template>

<script lang="ts" setup>
import InputText from 'primevue/inputtext';
import { ref, onMounted, computed } from 'vue';
import Button from '@/components/Button.vue';
import { vMaska } from 'maska';
import { useToast } from 'primevue/usetoast';
import { useRoute, useRouter } from 'vue-router';
import { AtivoService } from '@/services/ativos/AtivosService';
import { AssetType } from '@/core/interfaces/AssetTypes';

const toast = useToast();
const router = useRouter();
const route = useRoute();
const code = ref('');
const issuerDocument = ref('');
const type = ref();
const loading = ref(false);
const selectedFundType = ref();
const originalForm = ref();
interface FundType {
  value: string;
}

const form = ref<{ issuerDocument: string; code: string; type: string; selectedFundType: FundType | null }>({
	issuerDocument: '',
	code: '',
	type: '',
	selectedFundType: null,
});


onMounted(() => {
	checkIsEditMode();
});

const formWatcher = computed(() => {
	if (originalForm.value === JSON.stringify(form.value)) {
		return false;
	}
	return true;
});

const checkIsEditMode = () => {
	if (route.params.mode === 'editar') {
		getAssetById();
		return true;
	}
	return false;
};

const handleFormSubmit = async () => {
	loading.value = true;
	const payload = {
		code: form.value.code,
		issuerDocument: form.value.issuerDocument.replace(/\D/g, ''),
		type: { code: form.value.type } as AssetType,
		fundType: form.value.selectedFundType?.value,
	};

	let response;

	if (!checkIsEditMode()) {
		response = await AtivoService.createAtivo(payload);
	} else {
		const fundId = route.params.id;
		response = await AtivoService.editAtivo(payload, fundId as string);
	}

	const createOrEditPast = !checkIsEditMode() ? 'Criado' : 'Editado';
	const createOrEditPresent = !checkIsEditMode() ? 'Criar' : 'Editar';

	if (!(response instanceof Error)) {
		toast.add({
			severity: 'success',
			summary: `Ativo ${createOrEditPast} com sucesso!`,
			detail: '',
			life: 5000,
		});

		router.push({ name: 'Ativos' });

		code.value = '';
		issuerDocument.value = '';
		type.value = '';
	} else {
		toast.add({
			severity: 'error',
			summary: `Erro ao ${createOrEditPresent} fundo.`,
			detail: response,
			life: 5000,
		});
	}

	loading.value = false;
};

const getAssetById = async () => {
	const response = await AtivoService.getAtivoById(route.params.id as string);

	if (response && !(response instanceof Error)) {
		form.value.code = response.code;
		form.value.issuerDocument = response.issuerDocument;
		form.value.type = response.type.code;
	}
};
</script>

<style lang="scss" scoped>
.custom-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1.2rem;
  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
}
</style>
