<template>
  <div class="boxv2 cursor-pointer">
    <h2 class="boxv2__title font-albert">{{ title }}</h2>
    <span class="boxv2__value"> {{ value }} </span>
    <div
      class="boxv2__circle"
      v-if="showCircle && getCircleClass()"
      :class="['boxv2__circle', getCircleClass()]"
    ></div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    required: false,
    default: "",
  },
  value: {
    type: String,
    required: false,
  },
  showCircle: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const getCircleClass = () => {
  switch (props.title.toLowerCase()) {
    case "total de fundos":
      return "bg-[#0092C8]";
    case "fundos enquadrados":
      return "bg-[#73CD1F]";
    case "fundos no período de carência":
      return "bg-[#D9BD04]";
    case "fundos desenquadrados":
      return "bg-[#E9585A]";
    case "ativos para revisar":
    case "cpr para revisar":
      return "bg-[#F5A623]";
    default:
      break;
  }
};
</script>

<style lang="scss" scoped>
.boxv2 {
  border: solid 1px #e6e6e6;
  padding: 26px;
  display: inline-block;
  position: relative;
  border-radius: 8px;
  width: 100%;
  @media (max-width: 992px) {
    margin-bottom: 12px;
  }
  &__title {
    text-align: center;
    font-weight: 500;
    text-align: center;
    display: block;
    font-size: 14px;
    line-height: 100%;
    margin-bottom: 6px;
    min-height: 28px;
  }
  &__value {
    text-align: center;
    font-weight: 700;
    text-align: center;
    display: block;
    font-size: 24px;
  }

  &__circle {
    width: 9px;
    height: 9px;
    display: block;
    border-radius: 100%;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
</style>
