<template>
  <div>
    <hr class="mb-5" />
    <div class="flex flex-col mt-4">
      <label for="type"> Tipo de Download: </label>
      <Dropdown
        :editable="true"
        placeholder="Selecionar..."
        v-model="selectedDownloadType"
        optionLabel="name"
        :options="downloadTypes"
        class="dropdown-custom"
      />
    </div>

    <hr class="my-5" />
    <div class="flex xl:justify-end gap-2">
      <Button
        :loading="loadingDownload"
        :disabled="loadingDownload"
        :label="'Exportar'"
        class="rounded-lg text-white px-5 py-2 bg-primary hover:bg-secondary"
        @click="download()"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import Button from "primevue/button";
import { onMounted, ref, watch } from "vue";
import { EnquadramentosService } from "@/services/enquadramentos/EnquadramentosService";
import { PdfService } from "@/services/pdf/PdfService";
import Dropdown from "primevue/dropdown";
import { useToast } from "primevue/usetoast";

const toast = useToast();
const emit = defineEmits(["submit", "close"]);

const props = defineProps({
  fundFrameworkId: {
    type: Number,
    required: true,
  },
  fundId: {
    type: Number,
    required: true,
  },
  date: {
    type: String,
    required: true,
  },
});

const loadingDownload = ref(false);
const selectedDownloadType = ref();
const downloadTypes = ref([
  { name: "EXCEL", value: "excel" },
  { name: "PDF", value: "pdf" },
]);

const download = async () => {
  if (!selectedDownloadType.value) {
    toast.add({
      severity: "warn",
      summary: "Você precisa selecionar um tipo de download.",
      detail: "",
      life: 5000,
    });
    return;
  }

  loadingDownload.value = true;

  if (selectedDownloadType.value.value === "excel") {
    await downloadExcelByFundAndDate(props.fundFrameworkId);
  } else if (selectedDownloadType.value.value === "pdf") {
    await downloadPdfByFundAndDate(props.fundFrameworkId);
  } else {
    return toast.add({
      severity: "warn",
      summary: `Tipo de download ${selectedDownloadType.value.value} inválido.`,
      detail: "",
      life: 5000,
    });
  }

  toast.add({
    severity: "success",
    summary: "Download realizado com sucesso.",
    detail: "",
    life: 5000,
  });

  loadingDownload.value = false;
};

const downloadExcelByFundAndDate = async (fundFrameworkId: number) => {
  try {
    const response =
      await EnquadramentosService.downloadXlsxById(fundFrameworkId);

    const content = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(content);
    const downloadLink = document.createElement("a");
    const fileName = `enquadramento-${fundFrameworkId}.xlsx`;
    downloadLink.href = url;
    downloadLink.download = fileName;
    downloadLink.click();
  } catch (error) {
    console.error(error);

    toast.add({
      severity: "error",
      summary: "Erro ao baixar arquivo",
      detail: error,
      life: 5000,
    });
  }
};

const downloadPdfByFundAndDate = async (fundFrameworkId: number) => {
  try {
    const response = await PdfService.downloadFundFrameworks(fundFrameworkId);
    const content = new Blob([response], { type: "application/pdf" });

    const url = window.URL.createObjectURL(content);
    const downloadLink = document.createElement("a");
    const fileName = `enquadramento-${fundFrameworkId}.pdf`;
    downloadLink.href = url;
    downloadLink.download = fileName;
    downloadLink.click();
  } catch (error) {
    console.error(error);

    toast.add({
      severity: "error",
      summary: "Erro ao baixar arquivo",
      detail: error,
      life: 5000,
    });
  }
};
</script>
