<template>
  <h2 class="text-text font-semibold text-[24px]">
    {{ !checkIsEditMode ? "Criar" : "Editar" }} Tipo de cpr
  </h2>

  <form class="mt-10" @submit.prevent="handleFormSubmit">
    <div class="custom-grid create-fund-wrapper">
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Tipo</label>
        <InputText required v-model="code" class="global-input" :loading="loading" disabled />
      </div>

      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Descrição</label>
        <InputText required v-model="description" class="global-input" :loading="loading" disabled />
      </div>

      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">É direito creditório?</label>
        <InputSwitch :loading="loading" v-model="isCreditRight" />
      </div>
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper mb-3" style="grid-column: 1;">
        <label for="username">Revisado?</label>
        <InputSwitch :loading="loading" v-model="reviewed" />
      </div>
    </div>
    <hr class="mt-5" />
    <div class="flex justify-end gap-5">
      <Button label="Voltar" class="bg-neutral-4 hover:bg-secondary-2 text-white mt-5 px-10 rounded-lg"
        @click="router.push({ name: 'cpr-type' })" />
      <Button :loading="loading" :disabled="loading" type="submit" :label="'Salvar'"
        class="bg-primary hover:bg-secondary-2 text-white mt-5 px-10 rounded-lg" />
    </div>
  </form>
  <FirstPositionsTable title="Primeiras Posições" :data="fiveOlderPositions" :loading="loading">
  </FirstPositionsTable>
</template>

<script lang="ts" setup>
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import { ref, onMounted } from 'vue';
import Button from '@/components/Button.vue';
import { useToast } from 'primevue/usetoast';
import { useRoute, useRouter } from 'vue-router';
import { CprTypesService } from '@/services/cpr-types/CprTypesService';
import { FundCprsService } from '@/services/fund-cprs/FundCprsService';
import { FundCprByCprType } from '@/core/interfaces/FundCprByCprType';
import FirstPositionsTable from '@/components/cpr-types/FirstPositionsTable.vue';

const toast = useToast();

const router = useRouter();
const route = useRoute();

const code = ref('');
const group = ref('');
const description = ref('');
const isCreditRight = ref<boolean>(false);
const reviewed = ref<boolean>(true);
const fiveOlderPositions = ref<FundCprByCprType[]>([]);

const loading = ref(false);

onMounted(() => {
	checkIsEditMode();
	getFiveOlderPositions();
});

const checkIsEditMode = () => {
	if (route.params.mode === 'editar') {
		getCprById();
		return true;
	}
	return false;
};

const handleFormSubmit = async () => {
	loading.value = true;
	const payload = {
		code: code.value,
		group: group.value,
		isCreditRight: isCreditRight.value,
		reviewed: reviewed.value,
		description: description.value,
	};

	let response;

	if (!checkIsEditMode()) {
		response = await CprTypesService.createCprType(payload);
	} else {
		const id = route.params.id;
		response = await CprTypesService.editCprType(payload, id as string);
	}

	const createOrEditPast = !checkIsEditMode() ? 'Criado' : 'Editado';
	const createOrEditPresent = !checkIsEditMode() ? 'Criar' : 'Editar';

	if (!(response instanceof Error)) {
		toast.add({
			severity: 'success',
			summary: `Tipo do cpr ${createOrEditPast} com sucesso!`,
			detail: '',
			life: 5000,
		});

		router.push({ name: 'cpr-type' });

		code.value = '';
		group.value = '';
		isCreditRight.value = false;
	} else {
		toast.add({
			severity: 'error',
			summary: `Erro ao ${createOrEditPresent} tipo do cpr.`,
			detail: response,
			life: 5000,
		});
	}

	loading.value = false;
};

const getCprById = async () => {
	const response = await CprTypesService.getCprTypeById(
    route.params.id as string
	);
	if (response && !(response instanceof Error)) {
		code.value = response.code;
		description.value = response.description ?? '';
		isCreditRight.value = response.isCreditRight;
	}
};

const getFiveOlderPositions = async () => {
	loading.value = true;
	const response = await FundCprsService.getOlderCprsByCprType(
    route.params.id as string
	);
	if (response && !(response instanceof Error)) {
		fiveOlderPositions.value = response;
	}
	if (response instanceof Error) {
		toast.add({
			severity: 'error',
			summary: 'Erro ao buscar as primeiras posições.',
			detail: response,
			life: 5000,
		});
	}
	loading.value = false;
};
</script>

<style lang="scss" scoped>
.custom-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1.2rem;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
}
</style>
