<template>
  <h2 class="text-text font-semibold text-[24px]">Lista de Fundos</h2>
  <div class="mt-5 flex items-center">
    <div class="flex-1">
      <Button
        class="global-button secondary-button"
        label="Voltar"
        @click="router.go(-1)"
      >
        <template #icon>
          <img src="@/assets/svgs/back.svg" alt="back icon" class="mr-1" />
        </template>
      </Button>
    </div>
    <div class="flex items-center gap-2">
      <SearchBox
        class="mt-5 flex items-center justify-end"
        style="height: 45px"
        :search-handler="searchHandler"
        placeholder="Buscar por Denominação Social..."
      />
      <!-- <Button
      class="bg-primary hover:bg-secondary px-5 py-3 text-white border-solid border-transparent border-2"
      label="Filtrar"
      @click="visible = true"
    >
      <template #icon>
        <img src="@/assets/svgs/filter.svg" alt="filter icon" class="mr-3" />
      </template>
    </Button> -->
      <Button
        class="mt-5 flex items-center gap-1 justify-end"
        label="Filtrar por"
        @click="visible = true"
      >
        <img
          src="@/assets/svgs/filter-black.svg"
          v-tooltip="'Filtrar por'"
          alt="Filtrar por"
        />
      </Button>
      <Button
        class="mt-5 flex items-center gap-1 justify-end"
        @click="visible = true"
      >
        <img
          src="@/assets/svgs/download-black.svg"
          v-tooltip="'Download'"
          alt="Download"
        />
      </Button>
    </div>
  </div>
  <TableLoading v-if="loading" class="mt-5" />

  <div v-show="!loading && funds.length > 0">
    <FundsTable
      :paginator-data="paginatorData"
      :data="funds"
      v-show="!loading && funds && paginatorData"
      @pageEvent="getFunds"
    />
  </div>

  <Dialog
    :draggable="false"
    class="max-w-[98%] h-[70vh] xl:h-[200px] xl:h-auto xl:w-[25rem] z-50"
    v-model:visible="visible"
    modal
    header="Filtrar por"
  >
    <hr class="mb-5" />

    <div class="flex flex-col mt-4">
      <label for="name"> Nome </label>
      <InputText v-model="name" />
    </div>
    <div class="flex flex-col mt-4">
      <label for="name"> CNPJ </label>
      <InputText type="text" v-model="cnpj" />
    </div>
    <div class="flex flex-col mt-4">
      <label for="name"> Gestor </label>
      <Dropdown
        @update:modelValue="getManagers($event)"
        :editable="true"
        :loading="loadingManagers"
        placeholder="Buscar..."
        v-model="manager"
        optionLabel="name"
        :options="managers"
        class="dropdown-custom"
      />
    </div>
    <div class="flex flex-col mt-4">
      <label for="name"> Tipo </label>
      <Dropdown
        :editable="true"
        :loading="loadingFundTypes"
        placeholder="Buscar tipo"
        v-model="selectedFundType"
        optionLabel="name"
        :options="fundsTypes"
        class="dropdown-custom"
      />
    </div>

    <div class="flex flex-col mt-4">
      <label for="name"> Classe CVM </label>
      <Dropdown
        :editable="true"
        :loading="loadingCvmClass"
        placeholder="Buscar Classe Cvm"
        v-model="selectedCvmClass"
        optionLabel="name"
        :options="cvmClasses"
        class="dropdown-custom"
      />
    </div>
    <!-- <div class="flex flex-col mt-4">
      <label for="name"> É entidade de investimento? </label>
      <InputSwitch v-model="isInvestimentEntity as any" />
    </div> -->

    <hr class="my-5" />

    <div class="flex xl:justify-end gap-2">
      <Button
        label="Limpar"
        class="rounded-lg text-white px-5 py-2 bg-neutral-4 hover:bg-primary-2"
        @click="clearFilters"
      />
      <Button
        :loading="loading"
        :disabled="loading"
        label="Filtrar"
        class="rounded-lg text-white px-5 py-2 bg-primary hover:bg-secondary"
        @click="getFunds()"
      />
    </div>
  </Dialog>

  <DialogDelete
    :draggable="false"
    v-model:visible="deleteDialogVisible"
    modal
    header="Remover usuário"
    :style="{ width: '25rem' }"
  >
    <h3 class="font-bold text-orange-300">
      Você tem certeza que deseja remover o usuáro selecionado?
    </h3>
    <hr class="mt-3" />
    <div class="flex justify-end gap-3">
      <Button
        label="Cancelar"
        class="bg-neutral-3 hover:bg-neutral-4 px-4 py-2 mt-3"
        @click="deleteDialogVisible = false"
      />
      <Button
        :loading="loading"
        :disabled="loading"
        label="Remover"
        class="bg-red-600 text-white hover:bg-red-900 hover:bg-neutral-4 px-4 py-2 mt-3"
      />
    </div>
  </DialogDelete>
</template>

<script setup lang="ts">
import Button from "primevue/button";
import FundsTable from "@/components/funds/Table.vue";
import { useRouter } from "vue-router";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DialogDelete from "primevue/dialog";
import TableLoading from "@/components/loading/TableLoading.vue";
import Dropdown from "primevue/dropdown";
import debounce from "@/common/debounce";
import SearchBox from "../../components/asset-types/SearchBox.vue";
import { onMounted, ref } from "vue";
import { Fund } from "@/core/interfaces/Funds";
import { FundService } from "@/services/funds/FundService";
import { ManagersService } from "@/services/managers/ManagersService";
import { Manager } from "@/core/interfaces/Managers";
import { useRoute } from "vue-router";

const route = useRoute();
const manager = ref<Manager>();
const managers = ref<Manager[]>([]);
const loadingManagers = ref(false);
const funds = ref<Fund[]>([]);
const loading = ref(false);
const visible = ref(false);
const name = ref("");
const cnpj = ref("");
const cpf = ref("");
const isInvestimentEntity = ref<boolean | null>(false);
const paginatorData = ref<any>({});
const deleteDialogVisible = ref(false);
const fundsTypes = ref<{ name: string; value: string }[]>([]);
const cvmClasses = ref<{ name: string; value: string }[]>([]);
const selectedFundType = ref();
const loadingFundTypes = ref(false);
const selectedCvmClass = ref();
const loadingCvmClass = ref(false);
const code = ref("");
const selectedWasReviewed = ref();
const router = useRouter();

onMounted(() => {
  if (route.query.type) {
    selectedFundType.value = {
      value: route.query.type,
      name: route.query.type,
    };
  }

  getFunds();
  getManagers();
  getFundsType();
  getCvmClass();
});

const getCvmClass = async () => {
  loadingCvmClass.value = true;
  const response = await FundService.getCvmClasses();

  if (response && !(response instanceof Error)) {
    const cvmClassTypes: { name: string; value: string }[] = [];

    response.forEach((i) => {
      const obj = { name: i, value: i };
      cvmClassTypes.push(obj);
    });

    cvmClasses.value = cvmClassTypes;
  }
  loadingCvmClass.value = false;
};

const getFundsType = async () => {
  loadingFundTypes.value = true;
  const response = await FundService.getFundsTypes();

  if (response && !(response instanceof Error)) {
    const fundTypes: { name: string; value: string }[] = [];

    response.forEach((i) => {
      const obj = { name: i, value: i };
      fundTypes.push(obj);
    });

    fundsTypes.value = fundTypes;
  }
  loadingFundTypes.value = false;
};

const getManagers = debounce(async (searchValue?: string) => {
  loadingManagers.value = true;

  const response = await ManagersService.getManagers(searchValue || "");

  if (response && !(response instanceof Error)) {
    managers.value = response.data;
  }

  loadingManagers.value = false;
}, 500);

const clearFilters = () => {
  name.value = "";
  cnpj.value = "";
  cpf.value = "";
  isInvestimentEntity.value = null;
  manager.value = undefined;
  selectedFundType.value = undefined;
  selectedCvmClass.value = undefined;
  getFunds();
};

const getFunds = async (pageData?: { page?: number }) => {
  loading.value = true;
  visible.value = false;

  const filters: {
    page?: number;
    cnpj: string;
    isEntityName: boolean;
    manager: number;
    name: string;
    type: string;
    cvmClass: string;
  } = {
    name: name.value,
    cnpj: cnpj.value.replace(/\D/g, ""),
    isEntityName: isInvestimentEntity.value as boolean,
    page: typeof pageData?.page == "number" ? pageData?.page + 1 : 1,
    manager: manager.value?.id as number,
    type: selectedFundType.value?.value,
    cvmClass: selectedCvmClass.value?.value,
  };

  const response = await FundService.getFunds(filters);

  if (!(response instanceof Error) && response.data) {
    funds.value = response.data;
    paginatorData.value = response.meta;
  }

  loading.value = false;
};
const getAssetTypes = async (pageData?: { page?: number }) => {
  loading.value = true;
  const filters = {
    code: code.value,
    reviewed: selectedWasReviewed.value?.value,
    page: typeof pageData?.page == "number" ? pageData?.page + 1 : 1,
  };
};
function searchHandler(s: string) {
  code.value = s;
  getAssetTypes();
}
</script>

<style lang="scss" scoped>
.secondary-button {
  color: #0092c8 !important;
  font-size: 16px;
  border: none !important;
  height: 11px;
}
input {
  border: solid 1px theme("colors.text.DEFAULT");
  padding: 0.2rem 1rem;
}
label {
  margin-bottom: 0.3rem;
  color: theme("colors.text.DEFAULT");
  font-weight: bold;
}
</style>
