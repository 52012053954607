<template>
  <h2 class="text-text font-semibold text-[24px]">Enquadramentos</h2>
  <Button
    class="global-button secondary-button"
    label="Voltar"
    @click="router.go(-1)"
  >
    <template #icon>
      <img src="@/assets/svgs/back.svg" alt="back icon" class="mr-1" />
    </template>
  </Button>
  <Alert
    v-show="reprocessedFundsCount && reprocessedFundsCount > 0"
    variant="warning"
    :total="reprocessedFundsCount"
    :updatedAtLastHours="updatedAtLastHours"
    @viewReprocessedFunds="viewReprocessedFunds"
  />

  <div class="flex justify-end"><CategoriesMenu /></div>

  <div class="mt-5 flex items-center gap-2 justify-end">
    <Button
      class="global-button primary-button flex"
      label="Filtrar por Data"
      @click="openFilterDateModal()"
    >
      <template #icon>
        <img
          src="@/assets/svgs/filter-date.svg"
          alt="Filtrar por Data"
          class="mr-3"
        />
      </template>
    </Button>
    <Button
      class="mt-5 flex items-center gap-1 justify-end"
      @click="openFilterModal()"
    >
      <img
        src="@/assets/svgs/filter-black.svg"
        v-tooltip="'Filtrar Fundo'"
        alt="Filtrar Fundo"
      />
    </Button>
    <Button
      class="mt-5 flex items-center gap-1 justify-end"
      @click="(visible = true), getFunds(), (activeMode = 'process')"
    >
      <img
        src="@/assets/svgs/refresh-blue.svg"
        v-tooltip="'Processar Fundo'"
        alt="Processar Fundo"
      />
    </Button>
  </div>

  <TableLoading v-if="loading" class="mt-5" />
  <div v-show="!loading && frameworks.length > 0">
    <EnquadramentosTable
      :paginator-data="paginatorData"
      :data="frameworks"
      @pageEvent="handlePageChange"
    />
  </div>
  <NoResultsFound
    @filter-date-click="openFilterDateModal()"
    v-if="!loading && frameworks.length == 0"
    @button-click="openFilterModal()"
  />
  <Dialog
    :draggable="false"
    v-model:visible="visible"
    modal
    :header="
      activeMode === 'process' ? 'Processar' : 'Filtrar' + ' enquadramento'
    "
    class="modal-dialog-frameworks"
  >
    <hr class="mb-5" />
    <div class="flex flex-col mt-4" v-if="activeMode === 'process'">
      <label for="name">
        {{ activeMode === "process" ? "Processar" : "Filtrar" }} por fundo
      </label>
      <Dropdown
        @update:modelValue="getFunds($event)"
        :editable="true"
        :loading="loadingFunds"
        placeholder="Buscar..."
        v-model="selectedFund"
        optionLabel="name"
        :options="funds"
        class="dropdown-custom h-[38px] pl-[7px]"
      />
    </div>
    <div class="flex flex-col mt-4">
      <label for="name"> Data </label>
      <Calendar
        v-model="date"
        inline
        dateFormat="dd/mm/yy"
        :max-date="maxDate"
      />
    </div>
    <hr class="my-5" />
    <div class="flex xl:justify-end gap-2">
      <Button
        @click="
          (selectedFund = ''),
            (date = undefined),
            (selectedStatus = ''),
            getFundFrameworks()
        "
        label="Limpar"
        class="rounded-lg text-white px-5 py-2 bg-neutral-4 hover:bg-primary-2"
        :disabled="loading"
      />
      <Button
        :loading="loadingProcessPost"
        :disabled="loading"
        :label="activeMode === 'process' ? 'Processar' : 'Filtrar'"
        class="rounded-lg text-white px-5 py-2 bg-primary hover:bg-secondary"
        @click="
          activeMode === 'process'
            ? processFund()
            : getFundFrameworks(router.currentRoute.value.query)
        "
      />
    </div>
    <div
      class="flex flex-col mt-[30px] pb-[30px]"
      v-if="activeMode != 'process'"
    >
      <label for="name" class="mb-[15px]"> Fundo </label>
      <span class="text-gray-600 font-semibold mb-2">
        Buscar fundo por nome:
      </span>
      <Dropdown
        v-model="fundToFilter"
        :editable="true"
        :options="funds"
        optionLabel="name"
        placeholder="Selecionar fundo por nome..."
        class="dropdown-custom h-[38px] pl-[7px]"
        id="fund-to-filter-dropdown"
        @click="fundDocumentToFilter = ''"
      />
      <hr class="my-5" />
      <div class="flex xl:justify-end gap-2 mb-[20px]">
        <Button
          @click="fundToFilter = ''"
          label="Limpar"
          class="rounded-lg text-white px-5 py-2 bg-neutral-4 hover:bg-primary-2"
        />
        <Button
          :label="'Filtrar'"
          class="rounded-lg text-white px-5 py-2 bg-primary hover:bg-secondary focus:bg-secondary active:bg-orange-500"
          @click="
            fundToFilter?.document
              ? router.push({
                  name: 'enquadramentos-lista',
                  query: {
                    fundCnpj: fundToFilter.document,
                    date: date?.toISOString().split('T')[0],
                  },
                })
              : void 0
          "
        />
      </div>
    </div>
  </Dialog>
  <!-- filter by date -->
  <Dialog
    :draggable="false"
    v-model:visible="visibleFilterDateModal"
    modal
    :header="'Filtrar por data'"
    class="modal-dialog-frameworks"
  >
    <FilterDateModal
      @submit="(filters) => handleFilterDate(filters)"
      @close="visibleFilterDateModal = false"
    />
  </Dialog>
  <!-- filter funds -->
  <Dialog
    :draggable="false"
    v-model:visible="visibleFilterModal"
    modal
    :header="'Filtrar'"
    class="modal-dialog-frameworks"
  >
    <FilterModal @close="visibleFilterModal = false" />
  </Dialog>

  <Dialog
    :draggable="false"
    v-model:visible="downloadDialogVisible"
    modal
    header="Exportar para Excel"
    class="modal-dialog-frameworks range-calendar"
  >
    <label for="date" class="text-text font-semibold">
      Selecionar Data para Exportar
      <Calendar
        selectionMode="range"
        :manualInput="false"
        :max-date="maxDate"
        v-model="downloadDate"
        id="date"
        inline
        class="w-full mt-5 range-calendar"
        dateFormat="yy/mm/dd"
      />
    </label>
    <hr class="my-5" />
  </Dialog>
</template>
<script setup lang="ts">
import Button from "primevue/button";
import EnquadramentosTable from "@/components/enquadramentos/TableGrouped.vue";
import FilterModal from "@/components/enquadramentos/FilterModal.vue";
import FilterDateModal from "@/components/enquadramentos/FilterDateModal.vue";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import Dialog from "primevue/dialog";
import TableLoading from "@/components/loading/TableLoading.vue";
import Alert from "@/components/Alert.vue";
import { onMounted, ref, watch } from "vue";
import { EnquadramentosService } from "@/services/enquadramentos/EnquadramentosService";
import { FrameworkGrouped } from "@/core/interfaces/Framework";
import { FundService } from "@/services/funds/FundService";
import { Fund } from "@/core/interfaces/Funds";
import { useToast } from "primevue/usetoast";
import { debounce } from "chart.js/helpers";
import { useRouter } from "vue-router";
import NoResultsFound from "@/components/NoResultsFound.vue";
import { formatDate } from "@/common/date";
import CategoriesMenu from "@/components/CategoriesMenu.vue";

const router = useRouter();
const reprocessedFundsCount = ref<number | undefined>(undefined);
const loadingProcessPost = ref(false);
const toast = useToast();
const loadingFunds = ref(false);
const loading = ref(false);
const visible = ref(false);
const visibleFilterDateModal = ref(false);
const visibleFilterModal = ref(false);
const date = ref<Date>();
const selectedFund = ref();
const fundToFilter = ref();
const fundDocumentToFilter = ref();
const downloadDialogVisible = ref(false);
const maxDate = ref(new Date());
const funds = ref<Fund[]>();
const frameworks = ref<FrameworkGrouped[]>([]);
const paginatorData = ref();
const activeMode = ref("");
const downloadDate = ref<Date>();
const selectedStatus = ref();
const updatedAtLastHours = ref(0);
const cacheFilters = ref();

onMounted(() => {
  getFundFrameworks(router.currentRoute.value.query);
  getFundReprocessedCount();
});

const openFilterDateModal = () => {
  visibleFilterDateModal.value = true;
  activeMode.value = "filter-date";
};

const openFilterModal = () => {
  visibleFilterModal.value = true;
  activeMode.value = "filter";
};

watch(
  () => ({ ...router.currentRoute.value.query }),
  (newQueryParams) => {
    getFundFrameworks(newQueryParams);
  }
);

const viewReprocessedFunds = async () => {
  router.push({ name: "relatorio-reprocessamentos" });
};
const getFundReprocessedCount = async () => {
  updatedAtLastHours.value = 24;
  const response = await EnquadramentosService.fundReprocessedCount(
    updatedAtLastHours.value
  );
  if (!(response instanceof Error) && response) {
    console.log("response", response);
    reprocessedFundsCount.value = response;
  }
};

const getFundFrameworks = async (filters?: any) => {
  console.log("filters", filters);

  loading.value = true;
  visibleFilterDateModal.value = false;
  cacheFilters.value = filters;

  const response =
    await EnquadramentosService.getFundGroupedFrameworks(filters);
  if (!(response instanceof Error) && response.data) {
    frameworks.value = response.data;
    paginatorData.value = response.meta;
  }
  loading.value = false;
};

const getFunds = debounce(async (searchValue?: string) => {
  loadingFunds.value = true;
  const response = await FundService.getFunds(undefined, searchValue || "");
  if (!(response instanceof Error) && response.data) {
    funds.value = response.data;
  }
  loadingFunds.value = false;
}, 700);

const processFund = async () => {
  loadingProcessPost.value = true;
  if (date.value) {
    const dateObject = new Date(date.value);
    const dateOnly = dateObject.toISOString().split("T")[0];
    const payload = {
      fundId: selectedFund?.value?.id as number,
      date: dateOnly,
    };
    try {
      await EnquadramentosService.processFundFramework(payload);
      toast.add({
        severity: "success",
        summary: "Processamento criado com sucesso!",
        detail: "",
        life: 5000,
      });
      setTimeout(() => {
        visible.value = false;
        getFundFrameworks();
      }, 1000);
    } catch (error) {
      console.log(error);
      toast.add({
        severity: "error",
        summary: "Erro ao processar enquadramento",
        detail: error,
        life: 5000,
      });
    }
  }
  loadingProcessPost.value = false;
};

const handleFilterDate = (filter: { date: Date[] }) => {
  const date = filter?.date;
  const filters: { startDate?: string; endDate?: string } = {};
  if (date) {
    if (date[0]) {
      filters.startDate = formatDate(date[0], "yyyy-MM-dd");
    }
    if (date[1]) {
      filters.endDate = formatDate(date[1], "yyyy-MM-dd");
    }
  }

  router.push({ query: { ...cacheFilters.value, ...filters } });
};

const handlePageChange = (pageData: { page: number }) => {
  const page = pageData.page + 1;
  router.push({
    query: { ...(cacheFilters.value as any), page },
  });
};
</script>
<style lang="scss" scoped>
.primary-button {
  color: #000000 !important;
  font-size: 15px;
  background-color: #fdfdfd !important;
  border-color: #f4f4f4 !important;
  border-radius: 8px;
  border: solid 1px;

  padding: 13px 13px !important;
}

.secondary-button {
  color: #0092c8 !important;
  font-size: 16px;
  border: none !important;
}

input {
  border: solid 1px theme("colors.text.DEFAULT");
  padding: 0.2rem 1rem;
}

label {
  margin-bottom: 0.3rem;
  color: theme("colors.text.DEFAULT");
  font-weight: bold;
}

.dropdown-custom {
  border: solid 1px theme("colors.text.DEFAULT");
  border-radius: 7px;
  position: relative !important;

  .p-dropdown-trigger {
    background-color: red !important;
  }
}
</style>
<style>
.dropdown-custom {
  border: solid 1px theme("colors.text.DEFAULT");
  border-radius: 7px;
  position: relative !important;

  .p-dropdown-trigger {
    background-color: theme("colors.secondary.DEFAULT");
    color: theme("colors.white.DEFAULT");
  }
}

label.mb-\[15px\] {
  margin-bottom: 15px !important;
}
</style>
