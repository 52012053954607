export function formatDate(date: Date | string, format?: string): string {
  const d = date ? new Date(date) : new Date();

  d.setHours(
    d.getHours() == 21 && d.getMinutes() == 0 ? d.getHours() + 3 : d.getHours()
  );

  if (!format) {
    return d.toLocaleDateString("pt-BR");
  }

  const options: Intl.DateTimeFormatOptions = {};

  switch (format.toLowerCase()) {
    case "yyyy-mm-dd":
      options.year = "numeric";
      options.month = "2-digit";
      options.day = "2-digit";
      return d.toISOString().split("T")[0];

    default:
      return d.toLocaleDateString("pt-BR");
  }
}

export function parseDate(date: string): Date {
  return new Date(date + "T00:00:00");
}
