<template>
  <div id="categories-menu">
    <router-link
      v-for="subroute in subroutes"
      :key="subroute.name"
      :to="subroute.path"
      exact-active-class="menu-item-active"
      active-class="menu-item-active"
      class="flex items-center"
    >
      <button :class="isSubrouteActive(subroute.path)">
        {{ subroute.meta.displayName }}
      </button>
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { ref, defineEmits } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const emit = defineEmits(['select-category']);

const getRouteSubitem = (item: any) => {
	switch (item.name) {
	case 'enquadramentos':
		return [
			{
				meta: {
					displayName: 'FIDC',
				},
				path: '/enquadramentos/fidc?cvmType=FIDC',
				name: 'fidc',
			},
			{
				meta: {
					displayName: 'FIA',
				},
				path: '/enquadramentos/fia/?cvmClass=Fundo de Ações',
				name: 'fia',
			},
			{
				meta: {
					displayName: 'ETF',
				},
				path: '/enquadramentos/etf/?anbimaClass=ETF – Renda Variável',
				name: 'etf',
			},
			{
				meta: {
					displayName: 'Demais Fundos',
				},
				path: '/enquadramentos/others/?cvmTypesToIgnore=FIDC&cvmClassesToIgnore=Fundo de Ações&anbimaClassesToIgnore=ETF – Renda Variável',
				name: 'demais-fundos',
			},
		];
	case 'Cadastros': {
		const cadastroSubRoutes = router
			.getRoutes()
			.filter(
				(route) => route.meta.isSubRoute && route.path.includes('cadastro')
			);

		return cadastroSubRoutes;
	}
	}
};

const subroutes = getRouteSubitem({
	name: 'enquadramentos',
	path: '/enquadramentos',
	redirect: '',
});

function selectCategory(category: string) {
	emit('select-category', category);
}

const isSubrouteActive = (subitemPath: string) => {
	return router.currentRoute.value.path === subitemPath
		? 'menu-item-active'
		: '';
};
</script>

<style scoped>
#categories-menu {
  display: flex;
  height: 31px;
  width: fit-content;
  background: #f4f4f4;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  margin-top: 1rem;
  gap: 15px;
}

#categories-menu a {
  border-radius: 4px;
}

button {
  background: #f4f4f4;
  font-size: 15px;
  line-height: 17px;
  padding: 3px 35px;
  height: fit-content;
  min-height: 21px;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #d4d4d4;
}

.menu-item-active {
  background-color: theme("colors.sidebar.button-hover");
  color: theme("colors.white.DEFAULT") !important;
}
</style>
