<template>
  <h2 class="text-text font-semibold text-xl xl:ml-5 mb-5 mt-5 xl:mb-0 xl:mt-0">
    Detalhes do Enquadramento
  </h2>
  <div class="enquadramento-details">
    <div style="max-width: 100vw; overflow-x: auto !important">
      <div class="flex flex-col xl:flex-row items-center justify-between">
        <div class="mt-5 flex items-center gap-1">
          <div class="flex-1">
            <Button
              @click="router.go(-1)"
              label="Voltar"
              class="global-button secondary-button"
              :disabled="loading"
            >
              <template #icon>
                <img
                  src="@/assets/svgs/back.svg"
                  alt="back icon"
                  class="mr-1"
                />
              </template>
            </Button>
          </div>
        </div>

        <div class="flex items-center flex-col xl:flex-row">
          <!-- TODO: precisa integrar esse botão -->
          <Button label=" " @click="visibleDownloadModal = true">
            <img
              src="@/assets/svgs/download-black.svg"
              v-tooltip="'Download'"
              alt="Download"
            />
          </Button>
          <Dialog
            :draggable="false"
            v-model:visible="visibleDownloadModal"
            modal
            :header="'Exportar'"
            class="modal-dialog-frameworks"
          >
            <Export
              :fund-framework-id="details?.id || 0"
              :fundId="details?.fund?.id || 0"
              :date="details?.date.split(`T`)[0] || ''"
              @close="visibleDownloadModal = false"
            />
          </Dialog>

          <div
            class="bg-[#F4f4f4] rounded-lg px-5 py-[7px] flex flex-col xl:flex-row items-center xl:ml-5 mb-5 mt-5 xl:mb-0 xl:mt-0"
          >
            <span class="font-medium">Data do Enquadramento</span>
            <span
              class="font-medium text-lg bg-primary block ml-3 px-[22px] py-[4px] rounded-[4px] text-white"
            >
              {{
                loading ? "..." : formatDate(details?.date ?? "", "dd-mm-yyyy")
              }}</span
            >
          </div>
        </div>
      </div>
      <div class="px-5 flex justify-center">
        <Skeleton class="mt-5" v-if="loading" />

        <TimelineCustom
          v-if="!loading"
          @timelineClick="getData"
          :items="timelineData"
          class="my-5 w-full"
        />
      </div>
      <div class="flex justify-between gap-5 flex flex-col xl:flex-row">
        <EnquadramentosCard class="w-full">
          <template #content>
            <div>
              <EnquadramentosBoxValue
                :centerTitle="true"
                :value="formatCurrency(Number(details?.wallet?.netWorth))"
                title="Patrimônio Total"
                :loading="loading"
              ></EnquadramentosBoxValue>
            </div>
          </template>
        </EnquadramentosCard>
        <EnquadramentosCard class="w-full">
          <template #content>
            <div>
              <EnquadramentosBoxValue
                :center-title="true"
                :value="fundInfo?.isInvestmentEntity ? 'SIM' : 'NÃO'"
                title="Entidade de Investimento?"
                :loading="loading"
              ></EnquadramentosBoxValue>
            </div>
          </template>
        </EnquadramentosCard>
        <EnquadramentosCard class="w-full">
          <template #content>
            <div>
              <!-- TODO: Não entendi o que é para ser exibido aqui -->
              <EnquadramentosBoxValue
                :value="formatDate(fundInfo?.updatedAt as string)"
                title="Data do Processamento"
                :loading="loading"
              ></EnquadramentosBoxValue>
            </div>
          </template>
        </EnquadramentosCard>
        <EnquadramentosCard class="w-full">
          <template #content>
            <div>
              <EnquadramentosBoxValue
                :loading="loading"
                :value="isCompliant()"
                title="Status"
                :is-status="true"
                :status="isCompliant()"
                :is-compliant-without-tolerance="
                  details?.isCompliantWithoutTolerance
                "
              ></EnquadramentosBoxValue>
            </div>
          </template>
        </EnquadramentosCard>
      </div>
      <div class="w-full">
        <EnquadramentosCard class="w-full">
          <template #content>
            <div class="grid grid-cols-12 gap-4 mt-[1rem]">
              <div class="col-span-12 xl:col-span-9">
                <EnquadramentosBoxValue
                  :value="details?.fund?.name"
                  title="Denominação Social"
                  :center-title="false"
                  :loading="loading"
                >
                </EnquadramentosBoxValue>
              </div>
              <div class="col-span-12 xl:col-span-3">
                <EnquadramentosBoxValue
                  :value="formatCnpj(details?.fund?.document as string)"
                  title="CNPJ"
                  :center-title="false"
                  :loading="loading"
                ></EnquadramentosBoxValue>
              </div>
            </div>
            <div class="grid grid-cols-12 gap-4 mt-[2rem]">
              <div class="col-span-12 xl:col-span-9">
                <EnquadramentosBoxValue
                  :value="details?.fund?.manager?.name"
                  title="Gestor"
                  :center-title="false"
                  :loading="loading"
                ></EnquadramentosBoxValue>
              </div>
            </div>
            <div class="grid grid-cols-12 gap-4 mt-[2rem]">
              <div class="col-span-12 xl:col-span-2">
                <EnquadramentosBoxValue
                  :value="
                    new Date(
                      details?.fund?.constitutionDate as string
                    ).toLocaleDateString('pt-br')
                  "
                  title="Constituição"
                  :center-title="false"
                  :loading="loading"
                ></EnquadramentosBoxValue>
              </div>
              <div class="col-span-12 xl:col-span-2">
                <EnquadramentosBoxValue
                  :value="
                    new Date(
                      details?.fund?.firstCapitalizationDate as any
                    ).toLocaleDateString('pt-br')
                  "
                  title="1ª Integralização"
                  :center-title="false"
                  :loading="loading"
                ></EnquadramentosBoxValue>
              </div>
              <div class="col-span-12 xl:col-span-2">
                <EnquadramentosBoxValue
                  :value="fundInfo?.cvmType"
                  title="Tipo CVM"
                  :center-title="false"
                  :loading="loading"
                ></EnquadramentosBoxValue>
              </div>
              <div class="col-span-12 xl:col-span-2">
                <EnquadramentosBoxValue
                  :value="fundInfo?.cvmClass"
                  title="Classe CVM"
                  :center-title="false"
                  :add-tool-tip="true"
                  :loading="loading"
                ></EnquadramentosBoxValue>
              </div>
              <div class="col-span-12 xl:col-span-2">
                <EnquadramentosBoxValue
                  :value="fundInfo?.cvmSituation"
                  title="Situação na CVM"
                  :center-title="false"
                  :add-tool-tip="true"
                  :loading="loading"
                ></EnquadramentosBoxValue>
              </div>
              <div class="col-span-12 xl:col-span-2">
                <EnquadramentosBoxValue
                  :value="formatDate(fundInfo?.registrationDate as string)"
                  title="Data de Registro"
                  :center-title="false"
                  :loading="loading"
                ></EnquadramentosBoxValue>
              </div>
            </div>
          </template>
        </EnquadramentosCard>
      </div>
      <EnquadramentosCard class="w-full">
        <template #content>
          <div>
            <EnquadramentosBoxValue
              :details="details"
              :is-message="true"
              :center-title="false"
              :no-bg="true"
              :value="formatCurrency(Number(details?.wallet?.netWorth))"
              title="Alertas"
              :loading="loading"
            >
            </EnquadramentosBoxValue>
          </div>
        </template>
      </EnquadramentosCard>

      <div class="grid grid-cols-12 gap-4 mt-[1rem]">
        <div class="overflow-custom col-span-12 xl:col-span-7">
          <div class="flex flex-col">
            <EnquadramentosCard class="">
              <template #content>
                <div>
                  <EnquadramentosBoxValue
                    title="Composição da carteira"
                    :no-bg="true"
                    :center-title="false"
                    :loading="loading"
                    :showDownloadButton="true"
                    @visualizarCarteira="visualizarCarteira"
                  >
                    <template #inner-content>
                      <div class="w-full">
                        <DataTable
                          :value="wallet"
                          class="clients-table overflow-custom"
                        >
                          <Column field="label" header="Descrição do ativo" />
                          <Column field="value" header="Valor Líquido">
                            <template #body="slotProps">
                              {{ formatCurrency(Number(slotProps.data.value)) }}
                            </template>
                          </Column>
                          <Column field="" header="% do PL">
                            <template #body="slotProps">
                              {{
                                (
                                  (slotProps.data.value /
                                    (details?.wallet?.netWorth || 1)) *
                                  100
                                ).toLocaleString("pt-BR", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })
                              }}%
                            </template>
                          </Column>
                        </DataTable>
                      </div>
                    </template>
                  </EnquadramentosBoxValue>
                </div>
              </template>
            </EnquadramentosCard>

            <EnquadramentosCard>
              <template #content>
                <div>
                  <EnquadramentosBoxValue
                    :type="getTitle()"
                    title="Cálculo do Enquadramento"
                    :no-bg="true"
                    :center-title="false"
                    :loading="loading"
                  >
                    <template #inner-content>
                      <div
                        style="
                          max-width: 100% !important;
                          overflow-x: auto !important;
                        "
                      >
                        <DataTable
                          :value="getCalcValues()"
                          class="clients-table overflow-custom mt-3"
                          v-if="details?.wallet"
                        >
                          <Column field="label" header="Descrição">
                            <template #body="slotProps">
                              <b>{{ slotProps.data.label }}</b>
                            </template>
                          </Column>
                          <Column field="netValue" header="Valor Líquido">
                            <template #body="slotProps">
                              {{ formatCurrency(Number(slotProps.data.value)) }}
                            </template>
                          </Column>
                          <Column field="" header="% do PL">
                            <template #body="slotProps">
                              <span class="percentage-pill">
                                {{
                                  Number(
                                    (slotProps.data.value /
                                      details.wallet.netWorth) *
                                      100
                                  ).toLocaleString("pt-BR", {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  })
                                }}%
                              </span>
                            </template>
                          </Column>
                        </DataTable>
                      </div>
                    </template>
                  </EnquadramentosBoxValue>
                </div>
              </template>
            </EnquadramentosCard>
          </div>
        </div>
        <div class="col-span-12 xl:col-span-5">
          <EnquadramentosCard :loading="loading" class="w-full">
            <template #content>
              <DoughnutChart
                :chartData="chartData"
                :options="chartOptions"
                v-if="details?.wallet"
                style="aspect-ratio: 1"
              />
            </template>
          </EnquadramentosCard>
        </div>
      </div>
    </div>
  </div>

  <Dialog
    :position="position"
    :draggable="false"
    v-model:visible="visible"
    modal
    header=""
    class="modal-carteira"
  >
    <template #header>
      <div
        class="xl:flex xl:items-center xl:justify-between xl:w-full xl:mr-10"
      >
        <h2 class="text-text font-medium font-albert text-[24px]">
          Visualização Detalhada da Carteira
        </h2>
        <button
          class="bg-secondary-7 text-white flex items-center gap-2 px-4 py-2 rounded-lg"
          @click="visibleDownloadModal = true"
        >
          Download <img src="../../assets/svgs/download-2.svg" alt="" />
        </button>
      </div>
    </template>
    <hr />

    <div class="mt-5 flex items-center gap-5 mb-5">
      <button
        v-for="(filtro, index) in filtros"
        :key="index"
        class="btn-filtro"
        :class="{ 'filtro-ativo': filtroAtivo === filtro }"
        @click="setFiltroAtivo(filtro)"
      >
        {{ filtro.label }}
      </button>
    </div>
    <TableLoading v-if="loadingModalTable" />

    <DataTable
      paginator
      :rows="10"
      dataKey="id"
      :loading="loading"
      :value="carteiraData"
      v-if="filtroAtivo.value === 'ATIVO' && !loadingModalTable"
      v-model:filters="filters"
      filterDisplay="menu"
      :globalFilterFields="[
        'asset_code',
        'asset_type_code',
        'group',
        'netValue',
      ]"
    >
      <template #header>
        <div class="flex justify-content-between w-full">
          <div class="flex justify-between w-full">
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Limpar"
              outlined
              @click="clearFilter()"
              :style="false"
            />

            <IconField iconPosition="right">
              <InputIcon>
                <i class="pi pi-search" />
              </InputIcon>
              <InputText
                class="global-input"
                v-model="filters['global'].value"
                placeholder="Pesquisar..."
              />
            </IconField>
          </div>
        </div>
      </template>

      <Column field="isCreditRight" header="É direito Creditório?" filter>
        <template #filter="{ filterModel }">
          <Dropdown
            v-model="filterModel.value"
            :options="statuses"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecione..."
            showClear
          >
            <template #option="slotProps">
              <CheckBox :value="slotProps.option.value" />
            </template>
          </Dropdown>
        </template>

        <template #body="slotProps">
          <CheckBox :value="slotProps.data.isCreditRight" />
        </template>
      </Column>

      <Column field="asset_code" header="Código do Ativo">
        <template #filter="{ filterModel }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter mt-3 global-input"
            placeholder="Código do ativo"
          />
        </template>
        <template #body="slotProps">
          <span>{{ slotProps.data.asset_code }}</span>
        </template>
      </Column>

      <Column field="asset_type_code" header="Tipo de Ativo">
        <template #filter="{ filterModel }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter mt-3 global-input"
            placeholder="Tipo de ativo"
          />
        </template>
        <template #body="slotProps">
          <span> {{ slotProps.data.asset_type_code }} </span>
        </template>
      </Column>

      <Column field="asset_code" header="Grupo">
        <template #filter="{ filterModel }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter mt-3 global-input"
            placeholder="Grupo"
          />
        </template>
        <template #body="slotProps">
          <span> {{ slotProps.data.group }} </span>
        </template>
      </Column>

      <Column field="netValue" header="Valor Líquido">
        <template #filter="{ filterModel }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter mt-3 global-input"
            placeholder="Valor líquido"
          />
        </template>
        <template #body="slotProps">
          <span> {{ formatCurrency(Number(slotProps.data.netValue)) }} </span>
        </template>
      </Column>

      <Column field="netValue" header="% do PL">
        <template #body="slotProps">
          <span v-if="details?.wallet?.netWorth">
            {{
              (
                (slotProps.data.netValue / details?.wallet?.netWorth) *
                100
              ).toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            }}%
          </span>
        </template>
      </Column>
    </DataTable>
    <DataTable
      class=""
      :value="carteiraData"
      v-if="!loadingModalTable && filtroAtivo.label === 'CPR'"
      v-model:filters="filters"
      filterDisplay="menu"
      :globalFilterFields="[
        'isCreditRight',
        'asset_code',
        'asset_type_code',
        'group',
        'netValue',
        'code',
        'description',
      ]"
    >
      <template #header>
        <div class="flex justify-content-between w-full">
          <div class="flex justify-between w-full">
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Limpar"
              outlined
              @click="clearFilter()"
              :style="false"
            />

            <IconField iconPosition="right">
              <InputIcon>
                <i class="pi pi-search" />
              </InputIcon>
              <InputText
                class="global-input"
                v-model="filters['global'].value"
                placeholder="Pesquisar..."
              />
            </IconField>
          </div>
        </div>
      </template>
      <Column field="isCreditRight" header="É direito Creditório?" filter>
        <template #filter="{ filterModel }">
          <Dropdown
            v-model="filterModel.value"
            :options="statuses"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecione..."
            showClear
          >
            <template #option="slotProps">
              <CheckBox :value="slotProps.option.value" />
            </template>
          </Dropdown>
        </template>

        <template #body="slotProps">
          <CheckBox :value="slotProps.data.isCreditRight" />
        </template>
      </Column>

      <Column field="code" header="Código da CPR">
        <template #filter="{ filterModel }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter mt-3 global-input"
            placeholder="Código da CPR"
          />
        </template>
        <template #body="slotProps">
          <span> {{ slotProps.data.code }} </span>
        </template>
      </Column>
      <Column field="description" header="Descrição">
        <template #filter="{ filterModel }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter mt-3 global-input"
            placeholder="Descrição"
          />
        </template>
        <template #body="slotProps">
          <span> {{ slotProps.data.description }} </span>
        </template>
      </Column>

      <Column field="netValue" header="Valor líquido">
        <template #filter="{ filterModel }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter mt-3 global-input"
            placeholder="Valor liquido"
          />
        </template>
        <template #body="slotProps">
          <span> {{ formatCurrency(Number(slotProps.data.netValue)) }} </span>
        </template>
      </Column>

      <Column field="liquidationDate" header="Data de Liquidação">
        <template #filter="{ filterModel }">
          <div>
            xd {{ filterModel.value }}

            <Calendar
              v-model="filterModel.value"
              dateFormat="dd/mm/yy"
              placeholder="mm/dd/yyyy"
              mask="99/99/9999"
            />
          </div>
        </template>
        <template #body="slotProps">
          <span>
            {{ formatDate(slotProps.data.liquidationDate, "DD/MM/YYYY") }}
          </span>
        </template>
      </Column>
    </DataTable>
  </Dialog>
</template>

<script setup lang="ts">
import CheckBox from "@/components/CheckBox.vue";
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { EnquadramentosService } from "@/services/enquadramentos/EnquadramentosService";
import { formatDate } from "@/common/date";
import { formatCurrency } from "@/common/formatCurrency";
import { FrameworkDetailsInterface } from "@/core/interfaces/FrameworkDetails";
import { FundInfo } from "@/core/interfaces/Funds";
import { FundService } from "@/services/funds/FundService";
import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { useToast } from "primevue/usetoast";
import { FundPositionService } from "@/services/fund-positions/FundPositionsService";
import { CarteiraData } from "@/core/interfaces/CarteiraData";
import Calendar from "primevue/calendar";
import TableLoading from "@/components/loading/TableLoading.vue";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import EnquadramentosCard from "@/components/enquadramentos/EnquadramentosCard.vue";
import EnquadramentosBoxValue from "@/components/enquadramentos/EnquadramentosBoxValue.vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Export from "@/components/enquadramentos/Export.vue";
import TimelineCustom from "@/components/TimelineCustom.vue";

interface ChartData {
  labels: string[];
  datasets: {
    data: number[];
    backgroundColor?: string[];
    options?: Record<string, unknown>;
  }[];
}

interface WalletItem {
  label: string;
  value: number;
}

const filters = ref();
const router = useRouter();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const timelineData = ref<any>([]);
// eslint-disable-next-line @typescript-eslint/no-explicit-any

const initFilters = () => {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    asset_code: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    asset_type_code: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    group: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    netValue: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    isCreditRight: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    code: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  };
};

const statuses = ref([
  { label: "Sim", value: true },
  { label: "Não", value: false },
]);

const clearFilter = () => {
  initFilters();
};

onBeforeMount(() => {
  initFilters();
});

const chartDraw = {
  id: "centerTextPlugin",
  beforeDraw(chart: Chart) {
    const { width } = chart;
    const { height } = chart;
    const { ctx } = chart;
    ctx.restore();

    ctx.font = "17px sans-serif";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";

    const text = `${formatCurrency(Number(details.value?.wallet?.netWorth))}`;
    const textX = Math.round(width / 2);
    const textY = Math.round(height / 2.5);

    ctx.fillText(text, textX, textY);
    ctx.save();
  },
};

Chart.register(...registerables, chartDraw);

const filtros = ref([
  { label: "Ativo", value: "ATIVO" },
  { label: "CPR", value: "CPR" },
]);

const visible = ref(false);
const visibleDownloadModal = ref(false);
const toast = useToast();
const loading = ref(false);
const route = useRoute();
const details = ref<FrameworkDetailsInterface>();
const fundInfo = ref<FundInfo>();
const wallet = ref<WalletItem[]>([]);

const getTitle = (): string => {
  if (!fundInfo.value) return "";

  const { cvmType, cvmClass } = fundInfo.value;
  const type = cvmType?.toLocaleLowerCase();
  const cvmClas = cvmClass?.toLocaleLowerCase();

  if (type === "fi" && cvmClas === "fundo de ações") {
    return "FIA";
  } else if (type === "fidc") {
    return "FIDC";
  } else if (type === "fi") {
    return "Demais fundos";
  }

  return cvmType;
};

const getCalcValues = (): { label: string; value: number | undefined }[] => {
  if (!fundInfo.value) return [];

  const { cvmType, cvmClass } = fundInfo.value;
  const type = cvmType?.toLocaleLowerCase();
  const cvmClas = cvmClass?.toLocaleLowerCase();

  if (type === "fi" && cvmClas === "fundo de ações") {
    return [
      {
        label: "Renda Variável",
        value: details.value?.wallet?.calcVariableIncomeNetValue,
      },
      {
        label: "Cotas de FIA",
        value: details.value?.rules?.totalInvestedInFia || 0,
      },
    ];
  } else if (type === "fidc") {
    return [
      {
        label: "Direitos Creditórios",
        value: details.value?.wallet?.portfolioCreditRightsTotal,
      },
      {
        label: "Direitos Creditórios (CPR)",
        value: details.value?.wallet?.cprCreditRightsTotal,
      },
      {
        label: "Direito Creditório por Equiparação",
        value: details.value?.rules?.sumOtherFidcsNetValue || 0,
      },
    ];
  } else if (type === "fi") {
    return [
      {
        label: "Fundos Elegíveis",
        value: details.value?.rules?.eligibleFundsData?.totalNetValue ?? 0,
      },
    ];
  }

  return [];
};

const position = ref<
  | "center"
  | "bottom"
  | "top"
  | "left"
  | "right"
  | "topleft"
  | "topright"
  | "bottomleft"
  | "bottomright"
>("center");
const chartData = ref<ChartData>({
  labels: [""],
  datasets: [
    {
      data: [],
      backgroundColor: [
        "#FFD700",
        "#76C2D9",
        "#2C5076",
        "#4A657A",
        "#2C2927",
        "#D4E157",
        "#8E44AD",
        "#FF6F61",
        "#6B8E23",
        "#F4A261",
        "#C5E1A5",
        "#BDB76B",
        "#B2BEBF",
        "#2E8B57",
        "#5D4037",
      ],
    },
  ],
});
const carteiraData = ref<CarteiraData[]>([]);
const chartOptions = ref({
  cutout: "69%",
  plugins: {
    doughnutInnerText: {
      espnVal: "",
    },
    legend: {
      position: "bottom",
      labels: {
        boxWidth: 14,
        borderRadius: 10,
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
    tooltip: {
      callbacks: {
        label: function (label: { raw: number }) {
          return formatCurrency(label.raw);
        },
      },
    },
    datalabels: {
      display: false,
    },
  },
});

const loadingModalTable = ref(false);

interface Filtro {
  label: string;
  value: string;
}

const filtroAtivo = ref<Filtro>({ label: "", value: "" });

onMounted(() => {
  getDetails();
  setFiltroAtivo(filtros.value[0]);
  getTimeLineData();
});

onBeforeUnmount(() => {
  Chart.unregister(chartDraw);
});

const getData = () => {
  getDetails();
  setFiltroAtivo(filtros.value[0]);
  getTimeLineData();
};

const getTimeLineData = async () => {
  const data = await EnquadramentosService.getTimeLineData(
    route.params.id as unknown as number
  );

  timelineData.value = data;

  if (Array.isArray(data) && data.length === 1) {
    timelineData.value.push({});
  }

  console.log("timelineData", timelineData.value);
};

const getCarteiraData = async () => {
  loadingModalTable.value = true;
  const date = details.value?.date?.split("T")[0];
  const id = Number(details.value?.fund?.id);
  if (!id || !date) return;
  try {
    const response = await FundPositionService.getFundCarteira(
      date as string,
      id
    );

    if (!(response instanceof Error)) {
      carteiraData.value = response;
    } else {
      toast.add({
        severity: "error",
        summary: "Erro ao buscar carteira",
        detail: response.message,
        life: 5000,
      });
    }
  } catch (err) {
    toast.add({
      severity: "error",
      summary: "Erro ao buscar carteira",
      detail: err,
      life: 5000,
    });
    return;
  } finally {
    loadingModalTable.value = false;
  }
};

const getCprCarteira = async () => {
  loadingModalTable.value = true;
  try {
    const response = await FundPositionService.getCprCarteira(
      details.value?.date.split("T")[0] as string,
      Number(details.value?.fund?.id)
    );

    if (!(response instanceof Error)) {
      carteiraData.value = response;
    } else {
      toast.add({
        severity: "error",
        summary: "Erro ao buscar carteira",
        detail: response.message,
        life: 5000,
      });
    }
  } catch (err) {
    toast.add({
      severity: "error",
      summary: "Erro ao buscar carteira",
      detail: err,
      life: 5000,
    });
    return;
  } finally {
    loadingModalTable.value = false;
  }
};

const setFiltroAtivo = (filtro: { label: string; value: string }) => {
  filtroAtivo.value = filtro;
  if (filtroAtivo.value.label === "CPR") {
    getCprCarteira();
  } else {
    getCarteiraData();
  }
};

const visualizarCarteira = () => {
  visible.value = true;
};

const formatCnpj = (cnpj: string) => {
  return cnpj?.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    "$1.$2.$3/$4-$5"
  );
};

const handleChartData = (wallet: WalletItem[]) => {
  const data = wallet;
  const values: number[] = [];

  if (data) {
    data?.forEach((i: WalletItem) => {
      values.push(Number(i.value));
    });

    chartData.value.labels = data.map((i: WalletItem) => i.label || "");
    chartData.value.datasets[0].data = values;
  }
};

const getFundInfo = async (fundDocument: string, date: string) => {
  const response = await FundService.getFundInfoOnDate(fundDocument, date);
  if (response && !(response instanceof Error)) {
    fundInfo.value = response;
  }
  loading.value = false;
};

const getFundWallet = async (fundId: number, date: string) => {
  const response = await FundService.getFundWalletOnDate(fundId, date);
  wallet.value = (response as WalletItem[]).filter(
    (el: WalletItem) => el.value >= 0.01 || el.value <= -0.01
  );

  handleChartData(wallet.value);
};

const isCompliant = () => {
  if (details.value?.isCompliant) {
    return "Enquadrado";
  } else if (
    !details.value?.isCompliant &&
    details.value?.isCompliant !== null
  ) {
    return "Não enquadrado";
  } else if (details.value.status == "failed") {
    return "Falha";
  } else {
    return "Aguardando";
  }
};

const getDetails = async () => {
  loading.value = true;
  const id = router.currentRoute.value.params.id;
  console.log(id);

  details.value = {} as any;

  const response = await EnquadramentosService.getFundFrameworkdDetails(
    id as string
  );

  if (response && !(response instanceof Error)) {
    if (response.wallet) {
      response.wallet.portfolio = [
        {
          assetGroup: "CPR",
          assetType: "CPR",
          fundDocument: "",
          netValue: response.wallet?.cprTotal,
        },
        ...(response.wallet?.portfolio || []),
      ];
    }
    details.value = response;
    getFundInfo(response.fund?.document, response.date.split("T")[0]);
    getFundWallet(response.fund?.id, response.date.split("T")[0]);
  }

  getCarteiraData();
};
</script>

<style lang="scss" scoped>
.secondary-button {
  color: #0092c8 !important;
  font-size: 16px;
  border: none !important;
  height: 11px;
}
.custom-grid-1 {
  display: grid;
  grid-template-columns: 70% 40%;
  padding: 0 2rem;
  gap: 2rem;

  @media (max-width: 1510px) {
    grid-template-columns: repeat(2, 50%);
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: 100%;
    padding: 0;
  }
}

#pie-chart {
  @media screen and (max-width: 1024px) {
    width: 300px;
    margin-top: 1rem;
  }
}

.info-input {
  display: block;
  color: theme("colors.primary.DEFAULT");
  padding: 0;
}

.btn-filtro {
  color: theme("colors.text.DEFAULT");
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: solid 1px transparent;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-bottom: solid 1px theme("colors.primary.DEFAULT");
  }
}

.filtro-ativo {
  color: theme("colors.primary.DEFAULT") !important;
  border-bottom: solid 1px theme("colors.primary.DEFAULT") !important;
}
</style>

<style lang="scss">
.total-table {
  tr th {
    color: theme("colors.primary.DEFAULT") !important;
    font-weight: bold !important;
    background-color: #d9d9d9 !important;
  }

  .percentage-pill {
    background-color: theme("colors.secondary.DEFAULT") !important;
    color: theme("colors.white.DEFAULT") !important;
    width: 80px;
    border-radius: 4px;
    display: block;
    margin: 0 auto;
  }
}

.grid-custom {
  display: grid;
  grid-template-columns: 45% 45%;
  gap: 12px;
}

.clients-table {
  thead span {
    white-space: nowrap;
  }
}
.p-datatable .p-datatable-tbody > tr {
  td {
    white-space: nowrap;
    font-weight: 500;
    font-size: 12px !important;
  }
}

.modal-carteira {
  width: 80vw;
}

.marker-custom {
  width: 14px;
  height: 14px;
  border: solid 2px #fff;
  padding: 4px;
  outline: 2px solid #008cba;
  background-color: #008cba;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  z-index: 99999;
}
.success-marker {
  background-color: #0092c8 !important;
  outline-color: #0092c8 !important;
}

.warning-marker {
  background-color: #f5a623 !important;
  outline-color: #f5a623 !important;
}

.error-marker {
  background-color: #d64550 !important;
  outline-color: #d64550 !important;
}
</style>

<style>
.overflow-custom {
  @media (max-width: 767.98px) {
    max-width: 100% !important;
    overflow-x: auto !important;
  }
}
</style>
