<template>
  <h2 class="text-text font-semibold text-[24px]">
    Relatório de Reprocessamentos
  </h2>

  <div class="mt-5 flex items-center gap-1">
    <div class="flex-1">
      <Button
        class="global-button secondary-button"
        label="Voltar"
        @click="router.go(-1)"
      >
        <template #icon>
          <img src="@/assets/svgs/back.svg" alt="back icon" class="mr-1" />
        </template>
      </Button>
    </div>
    <div class="flex gap-2">
      <Button :disabled="true" @click="downloadDialogVisible = true">
        <img
          src="@/assets/svgs/download-black.svg"
          v-tooltip="'Download'"
          alt="Download"
        />
      </Button>
      <Button @click="((visible = true), (activeMode = 'filter'), getFunds())">
        <img
          src="@/assets/svgs/filter-black.svg"
          v-tooltip="'Filtrar'"
          alt="Filtrar"
        />
      </Button>
    </div>
  </div>
  <TableLoading v-if="loading" class="mt-5" />
  <div v-show="!loading && reprocessedFunds.length > 0">
    <TableReprocesseds
      :paginator-data="paginatorData"
      :data="reprocessedFunds"
      @pageEvent="viewReprocessedFunds"
      class="reprocesed-table"
    />
    <NoData v-if="!loading && reprocessedFunds.length == 0" />
  </div>
  <Dialog
    :draggable="false"
    v-model:visible="visible"
    modal
    :header="
      activeMode === 'process' ? 'Processar' : 'Filtrar' + ' enquadramento'
    "
    class="modal-dialog-reprocessedFunds"
  >
    <hr class="mb-5" />
    <div class="flex flex-col mt-4" v-if="activeMode === 'process'">
      <label for="name">
        {{ activeMode === "process" ? "Processar" : "Filtrar" }} por fundo
      </label>
      <Dropdown
        @update:modelValue="getFunds($event)"
        :editable="true"
        :loading="loadingFunds"
        placeholder="Buscar..."
        v-model="selectedFund"
        optionLabel="name"
        :options="funds"
        class="dropdown-custom"
      />
    </div>
    <div class="flex flex-col mt-4">
      <label for="name"> Data </label>
      <Calendar
        v-model="date"
        inline
        dateFormat="dd/mm/yy"
        :max-date="maxDate"
      />
    </div>
    <hr class="my-5" />
    <div class="flex xl:justify-end gap-2">
      <Button
        @click="
          ((selectedFund = ''),
          (date = undefined),
          (selectedStatus = ''),
          getFundFrameworks())
        "
        label="Limpar"
        class="rounded-lg text-white px-5 py-2 bg-neutral-4 hover:bg-primary-2"
        :disabled="loading"
      />
      <Button
        :loading="loadingProcessPost"
        :disabled="loading"
        :label="activeMode === 'process' ? 'Processar' : 'Filtrar'"
        class="rounded-lg text-white px-5 py-2 bg-primary hover:bg-secondary"
        @click="
          activeMode === 'process'
            ? processFund()
            : getFundFrameworks(undefined, true)
        "
      />
    </div>
  </Dialog>
  <Dialog
    :draggable="false"
    v-model:visible="downloadDialogVisible"
    modal
    header="Exportar para Excel"
    class="modal-dialog-reprocessedFunds range-calendar"
  >
    <label for="date" class="text-text font-semibold">
      Selecionar Data para Exportar
      <Calendar
        selectionMode="range"
        :manualInput="false"
        :max-date="maxDate"
        v-model="downloadDate"
        id="date"
        inline
        class="w-full mt-5 range-calendar"
        dateFormat="yy/mm/dd"
      />
    </label>
    <hr class="my-5" />
  </Dialog>
</template>
<script setup lang="ts">
import Button from "primevue/button";
import TableReprocesseds from "@/components/enquadramentos/TableReprocesseds.vue";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import Dialog from "primevue/dialog";
import TableLoading from "@/components/loading/TableLoading.vue";
import NoData from "@/components/NoData.vue";
import { onMounted, ref } from "vue";
import { EnquadramentosService } from "@/services/enquadramentos/EnquadramentosService";
import { FrameworkGrouped } from "@/core/interfaces/Framework";
import { FundService } from "@/services/funds/FundService";
import { Fund } from "@/core/interfaces/Funds";
import { useToast } from "primevue/usetoast";
import { debounce } from "chart.js/helpers";
import { useRouter } from "vue-router";

const router = useRouter();

const loadingProcessPost = ref(false);
const toast = useToast();
const loadingFunds = ref(false);
const loading = ref(false);
const visible = ref(false);
const date = ref<Date>();
const selectedFund = ref();
const downloadDialogVisible = ref(false);
const maxDate = ref(new Date());
const funds = ref<Fund[]>();
const reprocessedFunds = ref<FrameworkGrouped[]>([]);
const paginatorData = ref();
const activeMode = ref("");
const downloadDate = ref<Date>();
const selectedStatus = ref();

onMounted(() => {
  viewReprocessedFunds();
});

const viewReprocessedFunds = async (page?: { page: number }) => {
  loading.value = true;
  try {
    const response = await EnquadramentosService.fundsReprocessed(
      page ? Number(page.page) : 1
    );

    if (!(response instanceof Error) && response.data) {
      reprocessedFunds.value = response.data as FrameworkGrouped[];
      paginatorData.value = response.meta;
    }
  } catch {
    toast.add({
      severity: "error",
      summary: "Erro ao buscar enquadramentos reprocessados",
      detail: "",
      life: 5000,
    });
  } finally {
    loading.value = false;
  }
};

const getFundFrameworks = async (
  pageData?: { page?: number },
  filter?: boolean
) => {
  loading.value = true;
  let filters: {
    page?: string;
    date?: string;
  } = {};
  if (pageData) {
    filters = {
      page: pageData.page ? (pageData.page + 1).toString() : "1",
    };
  }
  if (filter) {
    if (date.value) {
      const ano = date.value.getFullYear();
      const mes = String(date.value.getMonth() + 1).padStart(2, "0");
      const dia = String(date.value.getDate()).padStart(2, "0");
      const dataFormatada = `${ano}-${mes}-${dia}`;
      filters.date = dataFormatada;
    }
  }
  const response =
    await EnquadramentosService.getFundGroupedFrameworks(filters);
  if (!(response instanceof Error) && response.data) {
    reprocessedFunds.value = response.data;
    paginatorData.value = response.meta;
    console.log("response", response);
  }
  loading.value = false;
};
const getFunds = debounce(async (searchValue?: string) => {
  loadingFunds.value = true;
  const response = await FundService.getFunds(undefined, searchValue || "");
  if (!(response instanceof Error) && response.data) {
    funds.value = response.data;
  }
  loadingFunds.value = false;
}, 700);
const processFund = async () => {
  loadingProcessPost.value = true;
  if (date.value) {
    const dateObject = new Date(date.value);
    const dateOnly = dateObject.toISOString().split("T")[0];
    const payload = {
      fundId: selectedFund?.value?.id as number,
      date: dateOnly,
    };
    try {
      await EnquadramentosService.processFundFramework(payload);
      toast.add({
        severity: "success",
        summary: "Processamento criado com sucesso!",
        detail: "",
        life: 5000,
      });
      setTimeout(() => {
        visible.value = false;
        getFundFrameworks();
      }, 1000);
    } catch (error) {
      console.log(error);
      toast.add({
        severity: "error",
        summary: "Erro ao processar enquadramento",
        detail: error,
        life: 5000,
      });
    }
  }
  loadingProcessPost.value = false;
};
</script>
<style lang="scss" scoped>
.secondary-button {
  color: #0092c8 !important;
  font-size: 16px;
  border: none !important;
  height: 11px;
}
input {
  border: solid 1px theme("colors.text.DEFAULT");
  padding: 0.2rem 1rem;
}
label {
  margin-bottom: 0.3rem;
  color: theme("colors.text.DEFAULT");
  font-weight: bold;
}
.dropdown-custom {
  border: solid 1px theme("colors.text.DEFAULT");
  border-radius: 7px;
  position: relative !important;
  .p-dropdown-trigger {
    background-color: red !important;
  }
}
</style>
<style>
.dropdown-custom {
  border: solid 1px theme("colors.text.DEFAULT");
  border-radius: 7px;
  position: relative !important;
  .p-dropdown-trigger {
    background-color: theme("colors.secondary.DEFAULT");
    color: theme("colors.white.DEFAULT");
  }
}
</style>
