<template>
  <h2 class="text-text font-semibold text-[24px]">Tipos de Ativos</h2>
  <div class="mt-5 flex items-center">
    <div class="flex-1">
      <Button
        class="global-button secondary-button"
        label="Voltar"
        @click="router.go(-1)"
      >
        <template #icon>
          <img src="@/assets/svgs/back.svg" alt="back icon" class="mr-1" />
        </template>
      </Button>
    </div>
    <div class="flex items-center gap-2">
      <SearchBox
        style="height: 45px"
        :search-handler="searchHandler"
        placeholder="Buscar por Tipo de Ativo..."
      />
      <Button label="Filtrar" @click="visible = true">
        <img
          src="@/assets/svgs/filter-black.svg"
          v-tooltip="'Filtrar'"
          alt="Filtrar"
        />
      </Button>
    </div>
  </div>
  <TableLoading v-if="loading" class="mt-5" />

  <TableLoading v-if="loading" class="mt-5" />

  <div v-show="!loading && assetTypes.length > 0">
    <AssetTypeTable
      :paginator-data="paginatorData"
      :data="assetTypes"
      v-show="!loading && assetTypes && paginatorData"
      @pageEvent="getAssetTypes"
    />
  </div>

  <NoResultsFound
    message="Nenhum resultado encontrado."
    :show-button="false"
    v-if="assetTypes.length <= 0"
  />

  <Dialog
    :draggable="false"
    v-model:visible="visible"
    modal
    header="Filtrar por:"
    class="modal-dialog-frameworks"
  >
    <hr class="mb-5" />

    <div class="flex flex-col mt-4">
      <label for="codigo"> Tipo </label>
      <InputText v-model="code" />
    </div>

    <div class="flex flex-col mt-4">
      <label for="codigo"> Revisado? </label>
      <Dropdown
        :editable="true"
        placeholder="Revisado?"
        v-model="selectedWasReviewed"
        optionLabel="name"
        :options="wasReviewedList"
        class="dropdown-custom"
      />
    </div>

    <hr class="my-5" />

    <div class="flex xl:justify-end gap-2">
      <Button
        @click="
          ((fundDocument = ''), (code = ''), (type = { value: '', label: '' }))
        "
        label="Limpar"
        class="rounded-lg text-white px-5 py-2 bg-neutral-4 hover:bg-primary-2"
      ></Button>
      <FilterButton
        label="Filtrar"
        class="bg-primary text-white hover:bg-secondary-2"
        @click="(getAssetTypes(), (visible = false))"
      />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { AssetType } from "@/core/interfaces/AssetTypes";
import { AssetTypesService } from "@/services/asset-types/AssetTypesService";
import { onMounted, ref } from "vue";
import AssetTypeTable from "@/components/asset-types/Table.vue";
import { MetaValues } from "@/core/interfaces/Customers";
import Button from "primevue/button";
import FilterButton from "@/components/Button.vue";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import TableLoading from "@/components/loading/TableLoading.vue";
import { useRoute, useRouter } from "vue-router";
import SearchBox from "../../components/asset-types/SearchBox.vue";
import NoResultsFound from "@/components/NoResultsFound.vue";

const assetTypes = ref<AssetType[]>([]);
const paginatorData = ref({} as MetaValues);
const loading = ref(false);

const visible = ref(false);
const code = ref("");
const fundDocument = ref("");

const type = ref<{ value: string; label: string }>();

const wasReviewedList = ref([
  { name: "Sim", value: "true" },
  { name: "Não", value: "false" },
]);
const selectedWasReviewed = ref();
const route = useRoute();
const router = useRouter();

onMounted(() => {
  if (route.query.reviewed) {
    selectedWasReviewed.value = wasReviewedList.value.find(
      (option) => option.value === route.query.reviewed
    );
  }
  getAssetTypes();
});

const getAssetTypes = async (pageData?: { page?: number }) => {
  loading.value = true;
  const filters = {
    code: code.value,
    reviewed: selectedWasReviewed.value?.value,
    page: typeof pageData?.page == "number" ? pageData?.page + 1 : 1,
  };
  console.log("caiu gets", filters);
  const response = await AssetTypesService.getAssetTypes(filters);

  if (response && !(response instanceof Error)) {
    assetTypes.value = response.data;
    paginatorData.value = response.meta;
  }
  loading.value = false;
};

function searchHandler(s: string) {
  code.value = s;
  getAssetTypes();
}
</script>
<style lang="scss" scoped>
.secondary-button {
  color: #0092c8 !important;
  font-size: 16px;
  border: none !important;
  height: 11px;
}
</style>
