<template>
  <h2 class="text-text font-semibold text-[24px]">
    Enquadramentos
    <span class="text-[18px] text-gray-600">
      {{ route.query.fundCnpj ? ` | fundo ${route.query.fundCnpj}` : "" }}
      {{
        route.query.date
          ? ` | ${formatDate(route.query.date + "T00:00:00")}`
          : ""
      }}
    </span>
  </h2>

  <div class="mt-5 flex items-center gap-1">
    <div class="flex-1">
      <Button
        @click="router.go(-1)"
        label="Voltar"
        class="global-button secondary-button"
        :disabled="loading"
      >
        <template #icon>
          <img src="@/assets/svgs/back.svg" alt="back icon" class="mr-1" />
        </template>
      </Button>
    </div>
    <div class="flex gap-4">
      <Button @click="downloadDialogVisible = true">
        <img
          src="@/assets/svgs/download-black.svg"
          v-tooltip="'Download'"
          alt="Download"
        />
      </Button>
      <Button label="Filtrar" @click="openFilterModal()">
        <img
          src="@/assets/svgs/filter-black.svg"
          v-tooltip="'Filtrar'"
          alt="Filtrar"
        />
      </Button>
    </div>
  </div>
  <TableLoading v-if="loading" class="mt-5" />
  <NoResultsFound
    v-if="!loading && frameworks.length == 0"
    @button-click="openFilterModal()"
  />
  <div v-show="!loading && frameworks.length > 0">
    <EnquadramentosTable
      @framework-details="handleFrameworkdDetails"
      v-show="!loading && frameworks"
      :paginator-data="paginatorData"
      :data="frameworks"
      @pageEvent="handlePageChange"
    />
    <NoData v-if="!loading && frameworks.length == 0" />
  </div>
  <Dialog
    :draggable="false"
    v-model:visible="visible"
    modal
    :header="
      activeMode === 'process' ? 'Processar' : 'Filtrar' + ' enquadramento'
    "
    class="modal-dialog-frameworks"
  >
    <FilterModal
      @submit="(filters) => getFundFrameworks(filters)"
      @close="visible = false"
    />
  </Dialog>
  <Dialog
    :draggable="false"
    v-model:visible="downloadDialogVisible"
    modal
    header="Exportar para Excel"
    class="modal-dialog-frameworks range-calendar"
  >
    <div class="flex flex-col mt-4">
      <label for="name"> Tipo de download:</label>
      <Dropdown
        :editable="true"
        placeholder="Tipo"
        v-model="selectedDownloadType"
        optionLabel="name"
        :options="downloadTypeList"
        class="dropdown-custom"
      />
    </div>
    <hr class="my-5" />
    <div class="flex xl:justify-end gap-2">
      <Button
        :loading="loadingXlsx"
        :disabled="loadingXlsx"
        :label="'Exportar'"
        class="rounded-lg text-white px-5 py-2 bg-primary hover:bg-secondary"
        @click="handleXlsxDownload"
      />
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import NoResultsFound from "@/components/NoResultsFound.vue";
import Button from "primevue/button";
import EnquadramentosTable from "@/components/enquadramentos/Table.vue";
import FilterModal from "@/components/enquadramentos/FilterModal.vue";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import { onMounted, ref, watch } from "vue";
import TableLoading from "@/components/loading/TableLoading.vue";
import { EnquadramentosService } from "@/services/enquadramentos/EnquadramentosService";
import { FundFramework } from "@/core/interfaces/Framework";
import NoData from "@/components/NoData.vue";
import { useRoute, useRouter } from "vue-router";
import { FundService } from "@/services/funds/FundService";
import { Fund } from "@/core/interfaces/Funds";
import { useToast } from "primevue/usetoast";
import { formatDate } from "../../common/date";
import { debounce } from "chart.js/helpers";
const route = useRoute();
const loadingProcessPost = ref(false);
const toast = useToast();
const loadingFunds = ref(false);
const loading = ref(false);
const visible = ref(false);
const date = ref<Date>();
const selectedFund = ref();
const router = useRouter();
const downloadDialogVisible = ref(false);
const funds = ref<Fund[]>();
const frameworks = ref<FundFramework[]>([]);
const paginatorData = ref({});
const activeMode = ref("");
const loadingXlsx = ref(false);

const selectedCvmType = ref();
const selectedCvmClass = ref();
const selectedAnbimaClass = ref();

const cacheFilters = ref();

//watch vue
watch(selectedFund, (value) => {
  if (typeof value !== "object") {
    getFunds(value);
  }
});
const selectedStatus = ref();
const selectedIsCompliant = ref();
const selectedIsCompliantWithoutTolerance = ref();
const fundDocument = ref<string>();
const selectedDownloadType = ref({ name: "EXCEL", value: "XLSX" });
const downloadTypeList = ref([{ name: "EXCEL", value: "XLSX" }]);

onMounted(async () => {
  await getFundFrameworks(route.query);
});

const openFilterModal = () => {
  console.log("===");
  clearFilters();
  visible.value = true;
  activeMode.value = "filter";
};

const clearFilters = () => {
  selectedFund.value = null;
  date.value = undefined;
  selectedStatus.value = null;
  fundDocument.value = "";
  selectedIsCompliant.value = null;
  selectedCvmType.value = null;
  selectedCvmClass.value = null;
};

const handleXlsxDownload = async () => {
  loadingXlsx.value = true;
  let filters: {
    fund?: number;
    status?: string;
    isCompliant?: boolean;
    isCompliantWithoutTolerance?: boolean;
    "fund.document"?: string;
    cvmType?: string;
    cvmClass?: string;
    anbimaClass?: string;
  } = {};
  if (selectedFund.value) {
    filters.fund = selectedFund.value.id;
  }
  if (fundDocument.value) {
    filters["fund.document"] = fundDocument.value;
  }
  if (selectedStatus.value) {
    filters.status = selectedStatus.value.value;
  }
  if (selectedIsCompliant.value) {
    filters.isCompliant = selectedIsCompliant.value.value;
  }
  if (selectedIsCompliantWithoutTolerance.value) {
    filters.isCompliantWithoutTolerance =
      selectedIsCompliantWithoutTolerance.value.value;
  }
  if (selectedCvmType.value) {
    filters.cvmType = selectedCvmType.value.value;
  }
  if (selectedCvmClass.value) {
    filters.cvmClass = selectedCvmClass.value.value;
  }
  if (selectedAnbimaClass.value) {
    filters.anbimaClass = selectedAnbimaClass.value.value;
  }

  if (route.query.cvmTypesToIgnore) {
    filters.cvmType = `$not:${route.query.cvmTypesToIgnore}`;
  }
  if (route.query.cvmClassesToIgnore) {
    filters.cvmClass = `$not:${route.query.cvmClassesToIgnore}`;
  }
  if (route.query.anbimaClassesToIgnore) {
    filters.anbimaClass = `$not:${route.query.anbimaClassesToIgnore}`;
  }
  const response = await EnquadramentosService.downloadXlsx(
    route.query.date as string,
    filters
  );
  if (response && !(response instanceof Error)) {
    //
    try {
      const file = response.data;
      const downloadLink = document.createElement("a");
      const fileName = "enquadramento.xlsx";
      downloadLink.href = file;
      downloadLink.download = fileName;
      downloadLink.click();
      downloadDialogVisible.value = false;
    } catch (error) {
      toast.add({
        severity: "error",
        summary: "Erro ao baixar arquivo",
        detail: error,
        life: 5000,
      });
      loadingXlsx.value = false;
    } finally {
      loadingXlsx.value = false;
    }
  } else {
    toast.add({
      severity: "error",
      summary: "Erro ao baixar arquivo",
      detail: response.message,
      life: 5000,
    });
    loadingXlsx.value = false;
  }
};

const getFundFrameworks = async (filters?: {
  page?: string;
  date?: string;
  fund?: number;
  status?: string;
  isCompliant?: boolean;
  isCompliantWithoutTolerance?: boolean;
  "fund.document"?: string;
  cvmType?: string;
  cvmClass?: string;
  anbimaClass?: string;
}) => {
  loading.value = true;
  cacheFilters.value = filters;
  const response = await EnquadramentosService.getFundFrameworks(filters);
  if (!(response instanceof Error) && response.data) {
    frameworks.value = response.data;
    paginatorData.value = response.meta;
  }
  loading.value = false;
};

const getFunds = debounce(async (searchValue?: string) => {
  const response = await FundService.getFunds(undefined, searchValue || "");
  if (!(response instanceof Error) && response.data) {
    funds.value = response.data;
  }
  loadingFunds.value = false;
}, 700);

const handleFrameworkdDetails = (details: number) => {
  router.push({ name: "enquadramentos-detalhes", query: { id: details } });
};

const handlePageChange = (pageData: { page: number }) => {
  const page = pageData.page + 1;
  router.push({
    query: { ...(cacheFilters.value as any), page },
  });
  getFundFrameworks({ ...(cacheFilters.value as any), page });
};
</script>
<style lang="scss" scoped>
.secondary-button {
  color: #0092c8 !important;
  font-size: 16px;
  border: none !important;
  height: 11px;
}
</style>
