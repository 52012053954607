<template>
    <div class="boxv2 h-full">          
        <div class="flex justify-between font-albert mb-1">
            <span class="font-bold">{{item.type}}</span>
            <span  class="font-bold text-primary-2">{{item.count}}</span>            
        </div>
        <div class="flex justify-between font-albert" v-for="(item, index) in distributionObjectToArray()" :key="index">
            <span class="text-[11px] text-black uppercase">{{item.name}}</span>
            <span >{{item.count}}</span>            
        </div>
    </div>
</template>

<script setup lang="ts">

const props = defineProps({
	item: {
		type: Object,
		required: true,
	}

});

const distributionObjectToArray = () => {
	console.log('prpsss!', props.item.distribution);
	const array = Object.entries(props.item.distribution as Record<string, { count: number }>).map(([key, value]) => ({
		name: key,
		count: value.count
	}));
	return array;
};


</script>

<style lang="scss" scoped>
.boxv2 {
    border: solid 1px #E6E6E6;
    padding: 26px;
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    &__title {
        text-align: center;
        font-weight: 700;
        text-align: center;
        display: block;
        font-size: 14px;
        line-height: 100%;
        margin-bottom: 6px;
    }
    &__value {
        text-align: center;
        font-weight: 700;
        text-align: center;
        display: block;
        font-size: 24px;
    }

    &__circle {
        width: 9px;
        height: 9px;
        display: block;        
        border-radius: 100%;
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
}

</style>