<template>
  <div>
    <hr class="mb-5" />
    <div class="flex flex-col mt-4">
      <label for="name"> Data </label>
      <Calendar
        v-model="date"
        inline
        dateFormat="dd/mm/yy"
        selectionMode="range"
      />
    </div>
    <hr class="my-5" />
    <div class="flex xl:justify-end gap-2">
      <Button
        @click="clearFilters()"
        label="Limpar"
        class="rounded-lg text-white px-5 py-2 bg-neutral-4 hover:bg-primary-2"
      />
      <Button
        :label="'Filtrar'"
        class="rounded-lg text-white px-5 py-2 bg-primary hover:bg-secondary"
        @click="submit()"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch, defineEmits } from "vue";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import { useToast } from "primevue/usetoast";

const toast = useToast();
const emit = defineEmits(["submit", "close"]);

const date = ref<Date[]>();

const clearFilters = () => {
  date.value = undefined;
  submit();
};

const submit = () => {
  if (!date.value) {
    toast.add({
      severity: "warn",
      summary: "Você precisa selecionar ao menos uma data.",
      detail: "",
      life: 5000,
    });
    return;
  }

  emit("submit", {
    date: date.value,
  });
  emit("close");
};
</script>
