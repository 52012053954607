<template>
  <div>
    <hr class="mb-5" />
    <div class="flex flex-col mt-4">
      <label for="name">
        {{ activeMode === "process" ? "Processar" : "Filtrar" }} por fundo
      </label>
      <Dropdown
        :editable="true"
        :loading="loadingFunds"
        placeholder="Buscar..."
        v-model="selectedFund"
        optionLabel="name"
        :options="funds"
        class="dropdown-custom"
      />
    </div>
    <div class="flex flex-col mt-4" v-if="activeMode === 'filter'">
      <label for="document"> CNPJ do fundo </label>
      <InputText class="modal-input" v-model="fundDocument" />
    </div>
    <div class="flex flex-col mt-4" v-if="activeMode === 'filter'">
      <label for="document">Data </label>
      <InputText type="date" class="modal-input" v-model="date as any" />
    </div>
    <div class="flex flex-col mt-4" v-if="activeMode === 'filter'">
      <label for="cvmType"> Tipo CVM </label>
      <Dropdown
        :editable="true"
        :loading="loadingCvmType"
        placeholder="Buscar tipo"
        v-model="selectedCvmType"
        optionLabel="name"
        :options="cvmTypeList"
        class="dropdown-custom"
      />
    </div>

    <div class="flex flex-col mt-4">
      <label for="name"> Enquadrado?</label>
      <Dropdown
        :editable="true"
        placeholder="Enquadrado?"
        v-model="selectedIsCompliant"
        optionLabel="name"
        :options="isCompliantList"
        class="dropdown-custom"
      />
    </div>
    <hr class="my-5" />
    <div class="flex xl:justify-end gap-2">
      <Button
        @click="clearFilters(), submit()"
        label="Limpar"
        class="rounded-lg text-white px-5 py-2 bg-neutral-4 hover:bg-primary-2"
        :disabled="loading"
      />
      <Button
        :loading="loadingProcessPost"
        :disabled="loading"
        :label="activeMode === 'process' ? 'Processar' : 'Filtrar'"
        class="rounded-lg text-white px-5 py-2 bg-primary hover:bg-secondary"
        @click="submit()"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import debounce from "@/common/debounce";
import { Fund } from "@/core/interfaces/Funds";
import Button from "primevue/button";
import { FundService } from "@/services/funds/FundService";
import { useToast } from "primevue/usetoast";
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { parseDate, formatDate } from "@/common/date";
import Dropdown from "primevue/dropdown";

const router = useRouter();
const props = defineProps({
  activeMode: {
    type: String,
    default: "filter",
  },
});
const emit = defineEmits(["submit", "close"]);
const loadingFunds = ref(false);
const selectedFund = ref();
const funds = ref<Fund[]>();
const fundDocument = ref<string>();
const selectedStatus = ref();
const status = ref([
  { name: "-", value: null },
  { name: "Em espera", value: "waiting" },
  { name: "Processando", value: "processing" },
  { name: "Finalizado", value: "finished" },
  { name: "Falha", value: "failed" },
]);
const loadingCvmType = ref(false);
const selectedCvmType = ref();
const selectedCvmClass = ref();
const cvmTypeList = ref<{ name: string; value: string }[]>([]);
const loadingCvmClass = ref(false);
const cvmClassList = ref<{ name: string; value: string }[]>([]);
const selectedIsCompliant = ref();
const isCompliantList = ref([
  // { name: "-", value: null },
  { name: "Sim", value: "true" },
  { name: "Não", value: "false" },
]);
const date = ref<Date>();
const selectedIsCompliantWithoutTolerance = ref();
const route = useRoute();
const selectedAnbimaClass = ref();
const loading = ref(false);
const loadingProcessPost = ref(false);
const anbimaClassList = ref<{ name: string; value: string }[]>([]);
const loadingAnbimaClass = ref(false);
const toast = useToast();

watch(selectedFund, (value) => {
  if (typeof value !== "object") {
    getFunds(value);
  }
});
onMounted(async () => {
  await getCvmType();
  await getCvmClass();
  await getAnbimaClasses();
  getFunds();

  if (route.query.date) {
    date.value = parseDate(route.query.date as string);
  }

  if (route.query.isCompliant) {
    selectedIsCompliant.value = isCompliantList.value.find(
      (option) => option.value == route.query.isCompliant
    );
  }
  if (route.query.isCompliantWithoutTolerance) {
    selectedIsCompliantWithoutTolerance.value = isCompliantList.value.find(
      (option) => option.value == route.query.isCompliantWithoutTolerance
    );
  }

  if (route.query.status) {
    selectedStatus.value = status.value.find(
      (option) => option.value === route.query.status
    );
  }

  if (route.query.cvmType) {
    selectedCvmType.value = cvmTypeList.value.find(
      (option) => option.value === route.query.cvmType
    );
  }

  if (route.query.cvmClass) {
    selectedCvmClass.value = cvmClassList.value.find(
      (option) => option.value === route.query.cvmClass
    );
  }

  if (route.query.cvmClass) {
    selectedCvmClass.value = cvmClassList.value.find(
      (option) => option.value === route.query.cvmClass
    );
  }

  if (route.query.anbimaClass) {
    selectedAnbimaClass.value = anbimaClassList.value.find(
      (option) => option.value === route.query.anbimaClass
    );
  }

  if (route.query?.fundCnpj) {
    const loadedFund = await FundService.getFunds({
      cnpj: route.query.fundCnpj as string,
      isEntityName: false,
      manager: 0,
      name: "",
      type: "",
      cvmClass: "",
    });
    if (!(loadedFund instanceof Error) && loadedFund.data.length > 0) {
      selectedFund.value = loadedFund.data[0];
    }
  }
});

const getFunds = debounce(async (searchValue?: string) => {
  loadingFunds.value = true;

  const response = await FundService.getFunds(undefined, searchValue || "");
  if (!(response instanceof Error) && response.data) {
    funds.value = response.data;
  }
  loadingFunds.value = false;
}, 700);

const clearFilters = () => {
  selectedFund.value = null;
  date.value = undefined;
  selectedStatus.value = null;
  fundDocument.value = "";
  selectedIsCompliant.value = null;
  selectedCvmType.value = null;
  selectedCvmClass.value = null;
};
const getCvmType = async () => {
  loadingCvmType.value = true;
  const response = await FundService.getFundsTypes();
  if (response && !(response instanceof Error)) {
    const cvmTypes: { name: string; value: string }[] = [];
    response.forEach((i) => {
      const obj = { name: i, value: i };
      cvmTypes.push(obj);
    });
    cvmTypeList.value = cvmTypes;
  }
  loadingCvmType.value = false;
};

const getCvmClass = async () => {
  loadingCvmClass.value = true;
  const response = await FundService.getCvmClasses();
  if (response && !(response instanceof Error)) {
    const cvmClasses: { name: string; value: string }[] = [];
    response.forEach((i) => {
      const obj = { name: i, value: i };
      cvmClasses.push(obj);
    });
    cvmClassList.value = cvmClasses;
  }
  loadingCvmClass.value = false;
};

const getAnbimaClasses = async () => {
  loadingAnbimaClass.value = true;
  const response = await FundService.getAnbimaClasses();
  if (response && !(response instanceof Error)) {
    const anbimaClasses: { name: string; value: string }[] = [];
    response.forEach((i) => {
      const obj = { name: i, value: i };
      anbimaClasses.push(obj);
    });
    anbimaClassList.value = anbimaClasses;
  }
  loadingAnbimaClass.value = false;
};

const submit = () => {
  let filters: {
    page?: string;
    date?: string;
    fund?: number;
    status?: string;
    isCompliant?: boolean;
    isCompliantWithoutTolerance?: boolean;
    "fund.document"?: string;
    cvmType?: string;
    cvmClass?: string;
    anbimaClass?: string;
  } = {};

  if (date.value) {
    filters.date = formatDate(date.value, "yyyy-MM-dd");
  }

  if (selectedFund.value) {
    filters.fund = selectedFund.value.id;
  }
  if (fundDocument.value) {
    filters["fund.document"] = fundDocument.value;
  }
  if (selectedStatus.value) {
    filters.status = selectedStatus.value.value;
  }
  if (selectedIsCompliant.value) {
    filters.isCompliant = selectedIsCompliant.value.value;
  }
  if (selectedIsCompliantWithoutTolerance.value) {
    filters.isCompliantWithoutTolerance =
      selectedIsCompliantWithoutTolerance.value.value;
  }
  if (selectedCvmType.value) {
    filters.cvmType = selectedCvmType.value.value;
  }
  if (selectedCvmClass.value) {
    filters.cvmClass = selectedCvmClass.value.value;
  }
  if (selectedAnbimaClass.value) {
    filters.anbimaClass = selectedAnbimaClass.value.value;
  }

  if (route.query.cvmTypesToIgnore) {
    filters.cvmType = `$not:${route.query.cvmTypesToIgnore}`;
  }
  if (route.query.cvmClassesToIgnore) {
    filters.cvmClass = `$not:${route.query.cvmClassesToIgnore}`;
  }
  if (route.query.anbimaClassesToIgnore) {
    filters.anbimaClass = `$not:${route.query.anbimaClassesToIgnore}`;
  }

  router.push({
    name: "enquadramentos-lista",
    query: { ...(filters as any) },
  });

  emit("submit", filters);
  emit("close");
};
</script>
