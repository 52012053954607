<template>
  <div class="xl:py-5 xl:px-5 content-wrapper" v-if="!loading">
    <div class="head flex items-center justify-between">
      <h2 class="font-albert font-semibold">Olá, {{ user?.name }}!</h2>
      <div class="flex items-center gap-2">
        <img src="../../assets/svgs/calendar.svg" alt="" />
        <span class="text-neutral-15 font-semibold">
          {{ formatDate(new Date().toString(), "dd-mm-yyyy") }}
        </span>
      </div>
    </div>
  </div>
  <div>
    <Skeleton v-if="loading" width="100%" height="100px" />
    <CardExpandComponent v-else title="Status" class="mb-5">
      <template #content>
        <div class="xl:flex xl:gap-2 xl:items-stretch">
          <BoxV2
            title="Total de Fundos"
            :value="values?.fundsCount?.toLocaleString('pt-BR') || '0'"
            @click="router.push({ name: 'Fundos' })"
            class="cursor-pointer"
          />
          <BoxV2
            title="Fundos Enquadrados"
            :value="
              values?.fundFrameworksCounts.countCompliant?.toLocaleString(
                'pt-BR'
              ) || '0'
            "
            @click="handleFundsEnquadradosRoute"
            class="cursor-pointer"
          />
          <BoxV2
            title="Fundos no Período de Carência"
            :showCircle="true"
            :value="
              (
                Number(
                  values?.fundFrameworksCounts
                    .countCompliantFundsWithinTolerance
                ) || 0
              ).toString()
            "
            @click="handleFundsGraceRoute"
          />
          <BoxV2
            :value="String(values?.assetsTypesCount)"
            :showCircle="true"
            title="Ativos para Revisar"
            @click="
              router.push({
                name: 'asset-type',
                query: { reviewed: 'false' },
              })
            "
          />

          <BoxV2
            :value="String(values?.cprTypesCount)"
            :showCircle="true"
            title="CPR para Revisar"
            @click="
              router.push({
                name: 'cpr-type',
                query: { reviewed: 'false' },
              })
            "
          />
          <BoxV2
            :value="
              values?.fundFrameworksCounts.countNonCompliant?.toLocaleString(
                'pt-BR'
              )
            "
            :showCircle="true"
            title="Fundos Desenquadrados"
            backgroundColor="rgba(233, 88, 90)"
            borderColor="white"
            outlineColor="rgba(233, 88, 90, 0.3)"
            @click="handleFundsDesenquadradosRoute"
          />

          <BoxV2
            @click="handleLastProcessingRoute"
            :value="formatDate(values?.fundFrameworksCounts?.lastDate ?? '')"
            title="Último Enquadramento:"
          />
        </div>
      </template>
    </CardExpandComponent>
    <Skeleton class="mt-5" v-if="loading" width="100%" height="100px" />
    <div class="grid-1" v-else>
      <CardExpandComponent title="Distribuição de Fundos" class="w-full">
        <template #content>
          <div class="distribution-grid">
            <div v-for="(item, index) in fundsDistributionArray" :key="index">
              <FundsDistribution :item="item" class="fund-item" />
            </div>
          </div>
        </template>
      </CardExpandComponent>
      <div>
        <CardExpandComponent title="Alertas">
          <template #content>
            <div class="alert-wrapper">
              <AlertV2
                :message="`${reprocessedFundsCount} fundos foram reprocessados nas últimas ${updatedAtLastHours} horas`"
                v-show="reprocessedFundsCount && reprocessedFundsCount > 0"
                variant="warning"
                :total="reprocessedFundsCount"
                :updatedAtLastHours="updatedAtLastHours"
                @redirectTo="viewReprocessedFunds"
              />
              <AlertV2
                v-show="values && values?.cprTypesCount > 0"
                variant="warning"
                :message="`${values?.cprTypesCount} CPRs estão pendente de Revisão!`"
                @redirectTo="
                  router.push({
                    name: 'cpr-type',
                    query: { reviewed: 'false' },
                  })
                "
              />
              <AlertV2
                v-show="values && values?.assetsTypesCount > 0"
                variant="warning"
                :message="`${values?.assetsTypesCount} Ativos estão pendente de Revisão!`"
                @redirectTo="
                  router.push({
                    name: 'asset-type',
                    query: { reviewed: 'false' },
                  })
                "
              />
            </div>
          </template>
        </CardExpandComponent>

        <CardExpandComponent
          title="Percentual de Fundos Enquadrados"
          class="mt-4 w-full"
        >
          <template #content>
            <div class="">
              <div class="message-fund">
                <span
                  >Status atual: {{ fundsPercentage }}% dos fundos estão
                  enquadrados.</span
                >
              </div>

              <div class="flex justify-center">
                <DoughnutChart
                  id="centerTextPlugin"
                  :chartData="chartData"
                  :options="chartOptions"
                  style="aspect-ratio: 1.5; margin-top: 20px"
                />
              </div>
            </div>
          </template>
        </CardExpandComponent>
      </div>
    </div>
    <Skeleton class="mt-5" v-if="loading" width="100%" height="100px" />
    <CardExpandComponent
      v-else
      title="Histórico dos últimos 5 dias:"
      class="mt-5"
    >
      <template #content>
        <EnquadramentosTable v-if="!loading && frameworks" :data="frameworks" />
      </template>
    </CardExpandComponent>
  </div>
</template>
<script setup lang="ts">
import { DashboardInterface } from "@/core/interfaces/Dashboard";
import { DashboarService } from "@/services/dashboard/Dashboard";
import { useToast } from "primevue/usetoast";
import { onBeforeUnmount, onMounted, ref } from "vue";

import { formatDate } from "@/common/date";
import { User } from "@/core/interfaces/User";
import { FrameworkGrouped } from "@/core/interfaces/Framework";
import Skeleton from "primevue/skeleton";
import CardExpandComponent from "@/components/CardExpandComponent.vue";
import BoxV2 from "@/components/BoxV2.vue";

import EnquadramentosTable from "@/components/enquadramentos/TableGrouped.vue";
import router from "@/router";

import FundsDistribution from "@/components/FundsDistribution.vue";
import AlertV2 from "@/components/AlertV2.vue";
import { ChartData } from "chart.js";
import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { EnquadramentosService } from "@/services/enquadramentos/EnquadramentosService";

const teste = {
  id: "centerTextPlugin",
  beforeDraw(chart: Chart) {
    const { width } = chart;
    const { height } = chart;
    const { ctx } = chart;
    ctx.restore();

    ctx.font = "24px sans-serif";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";

    const text = `${fundsPercentage.value}%`;
    const textX = Math.round(width / 2);
    const textY = Math.round(height / 1.9);

    ctx.fillText(text, textX, textY);
    ctx.save();
  },
};

Chart.register(...registerables, teste);

const fundsPercentage = ref();
const values = ref<DashboardInterface>();
const user = ref<User>();
const lastDate = ref();
const reprocessedFundsCount = ref<number | undefined>(undefined);
const toast = useToast();
const loading = ref(false);
const frameworks = ref<FrameworkGrouped[]>([]);
const viewReprocessedFunds = async () => {
  router.push({ name: "relatorio-reprocessamentos" });
};
const updatedAtLastHours = ref(0);
const fundsDistribution = ref<
  Record<string, { count: number; distribution: number }>
>({});
const fundsDistributionArray = ref<
  { type: string; count: number; distribution: number }[]
>([]);

onBeforeUnmount(() => {
  Chart.unregister(teste);
});

onMounted(async () => {
  loading.value = true;
  await Promise.all([
    getDashboardValues(),
    setUser(),
    getFundReprocessedCount(),
  ]);

  loading.value = false;
});

const handleLastProcessingRoute = () => {
  router.push({
    name: "enquadramentos-lista",
    params: {
      date: lastDate.value.split("T")[0],
    },
    query: {
      date: lastDate.value.split("T")[0],
      isCompliant: "true",
    },
  });
};

const handleFundsEnquadradosRoute = () => {
  router.push({
    name: "enquadramentos-lista",
    params: {
      date: formatDate(
        values.value?.fundFrameworksCounts?.lastDate || "",
        "yyyy-mm-dd"
      ),
    },
    query: {
      date: formatDate(
        values.value?.fundFrameworksCounts?.lastDate || "",
        "yyyy-mm-dd"
      ),
      isCompliant: "true",
    },
  });
};

const handleFundsGraceRoute = () => {
  router.push({
    name: "enquadramentos-lista",
    params: {
      date: formatDate(
        values.value?.fundFrameworksCounts?.lastDate || "",
        "yyyy-mm-dd"
      ),
    },
    query: {
      date: formatDate(
        values.value?.fundFrameworksCounts?.lastDate || "",
        "yyyy-mm-dd"
      ),
      isCompliant: "true",
      isCompliantWithoutTolerance: "false",
    },
  });
};

const handleFundsDesenquadradosRoute = () => {
  router.push({
    name: "enquadramentos-lista",
    params: {
      date: lastDate.value.split("T")[0],
    },
    query: {
      isCompliant: "false",
    },
  });
};

const getFundReprocessedCount = async () => {
  updatedAtLastHours.value = 24;
  const response = await EnquadramentosService.fundReprocessedCount(
    updatedAtLastHours.value
  );
  if (!(response instanceof Error) && response) {
    reprocessedFundsCount.value = response;
  }
};
const chartOptions = ref({
  cutout: "69%",
  rotation: 160,
  plugins: {
    beforeDraw: (chart: Chart) => {
      const { width } = chart;
      const { height } = chart;
      const { ctx } = chart;
      ctx.restore();

      ctx.font = "24px sans-serif";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";

      const text = `${fundsPercentage.value}`;
      const textX = Math.round(width / 2);
      const textY = Math.round(height / 1.9);

      ctx.fillText(text, textX, textY);
      ctx.save();
    },
    doughnutInnerText: {
      espnVal: "",
    },
    legend: {
      display: false,
      position: "bottom",
      labels: {
        boxWidth: 14,
        borderRadius: 10,
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },

    datalabels: {
      display: false,
    },

    tooltip: {
      callbacks: {
        label: function (context: { label: string; raw: unknown }) {
          if (context.label === "Enquadrados") {
            return `${context.label}: ${context.raw}`;
          }
          return `${context.label}: ${context.raw}`;
        },
      },
    },
  },
});
const chartData = ref<ChartData<"doughnut">>({
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: ["#73CD1F", "#E0E0E0"],
      hoverOffset: 4,
    },
  ],
});

const adjustHeights = () => {
  const fundsBox = document.querySelectorAll(".fund-item");
  console.log("fundsBox", fundsBox);
  if (fundsBox) {
    let maxHeight = 0;
    fundsBox.forEach((item) => {
      console.log("item", item);
      const itemHeight = item.clientHeight;
      if (itemHeight > maxHeight) {
        maxHeight = itemHeight;
      }
      (item as HTMLElement).style.height = `${maxHeight}px`;
    });
  }
};

const setUser = async () => {
  const userData = localStorage.getItem("user");
  user.value = JSON.parse(userData || "{}");
};
const getDashboardValues = async () => {
  const response = await DashboarService.getDashboardValues();
  if (response && !(response instanceof Error)) {
    values.value = response;

    fundsPercentage.value = (
      (Number(values?.value?.fundFrameworksCounts.countCompliant) /
        Number(values?.value?.fundsCount)) *
      100
    ).toFixed(2);

    chartData.value.labels = ["Enquadrados", "Não enquadrados"];

    chartData.value.datasets[0].data = [
      Number(values?.value?.fundFrameworksCounts.countCompliant),
      Number(
        values?.value?.fundsCount -
          Number(values?.value?.fundFrameworksCounts.countCompliant)
      ),
    ];

    frameworks.value = response.fundsFrameworksGrouped;
    lastDate.value = response.fundsFrameworksGrouped[0].date;
    fundsDistribution.value = response.fundsDistribution as unknown as Record<
      string,
      { count: number; distribution: number }
    >;

    const objectToArray = Object.keys(fundsDistribution.value).map((key) => {
      return {
        type: key,
        count: (
          fundsDistribution.value[key] as {
            count: number;
            distribution: number;
          }
        ).count,
        distribution: fundsDistribution.value[key].distribution,
      };
    });

    fundsDistributionArray.value = objectToArray;
    console.log(fundsDistributionArray.value);
  } else {
    toast.add({
      severity: "error",
      summary: "Erro ao buscar dados da dashboard",
      detail: "",
      life: 5000,
    });
  }

  adjustHeights();
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 22px;
  color: #333333;
  font-weight: 400;
  padding-bottom: 10px;
  margin-top: 20px;
  &.history-title {
    margin-bottom: -40px;
  }
}
.head {
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid #8e8e8ec5;
  h2 {
    font-size: 26px;
    font-weight: 500;
    color: #333333;
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  @media (max-width: 1027px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.funds-box-wrapper {
  display: flex;
  height: 88%;
  gap: 0.8rem;
  @media (max-width: 1600px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    justify-items: center;
    min-width: 100% !important;
  }
  @media (max-width: 1400.98px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 1199.98px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 992.98px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.content-wrapper {
  @media (max-width: 1200px) {
    max-width: calc(100% - 237px);
    overflow-x: auto;
    padding: 0;
    display: block;
    margin: auto;
  }
  @media (max-width: 767.98px) {
    max-width: 100%;
  }
}

.distribution-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  @media (max-width: 1199px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-1 {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 24px;
  .distribution-item {
    box-sizing: border-box;
  }
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
  }
}

.card-expand-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.message-fund {
  background: #73cd1f30;
  border-radius: 5px;
  color: #73cd1f;
  padding: 14px 22px;
  margin-top: 6px;
}
</style>
